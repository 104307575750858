import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";
import { CartModel } from "../models/CartModel";

export const cartAPI = createApi({
  reducerPath: "cartAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL + "cart/",
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCartItems: builder.query<ResponseModel, void>({
      query: () => "all/",
      keepUnusedDataFor: 0.0001,
    }),
    addToCart: builder.mutation<ResponseModel, any>({
      query: (item: CartModel) => ({
        url: "add",
        method: "POST",
        body: {
          productId: item.productId,
          quantity: item.quantity,
          image: item.image,
          name: item.name,
          attributes: item.attributes,
          woocommerceId: item.woocommerceId,
          ...(item.isRenewal && { isRenewal: item.isRenewal }),
        },
      }),
    }),
    updateCart: builder.mutation<
      ResponseModel,
      { productId: number; quantity: number }
    >({
      query: (params) => ({
        url: "update",
        method: "PUT",
        body: {
          productId: params.productId,
          quantity: params.quantity,
        },
      }),
    }),
    deleteCartItem: builder.mutation<ResponseModel, any>({
      query: (productId: number) => ({
        url: `delete/${productId}`,
        method: "DELETE",
        body: {},
      }),
    }),
  }),
});

export const {
  useGetAllCartItemsQuery,
  useUpdateCartMutation,
  useDeleteCartItemMutation,
  useAddToCartMutation,
} = cartAPI;
