import React from "react";
import { ProductModel } from "../../models/ProductModel";
import { Delete, Star } from "@mui/icons-material";
import {
  Card,
  Typography,
  Icon,
  CardMedia,
  CardContent,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppColors } from "../../common/AppColors";
import { Images } from "../../common/Images";

interface Props {
  product: ProductModel;
  currency: string;
  handleClickDelete(parentId: number, productId: number): void;
  styles: any;
}

export const LongProductCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const product = props.product;
  let styles = props.styles;

  function goToProductPage() {
    navigate("/product/" + product.id, {
      state: props.product,
    });
  }

  function handleClickDelete(event: React.MouseEvent) {
    event.stopPropagation();
    props.handleClickDelete(product.parentId, product.id);
  }

  return (
    <>
      <Card variant="outlined" sx={styles.wrapper}>
        <div
          onClick={goToProductPage}
          className="center-content"
          style={styles.clickableDiv}
        >
          <CardMedia
            component="img"
            sx={styles.image}
            image={
              product.images[0] ? product.images[0].src : Images.placeholder
            }
            loading="lazy"
          />
          <div style={styles.content}>
            <IconButton onClick={handleClickDelete} sx={styles.deleteButton}>
              <Delete fontSize="small" />
            </IconButton>

            <CardContent>
              <Typography
                fontSize={"large"}
                variant="body1"
                width={"70%"}
                overflow={"hidden"}
                height={25}
              >
                {product.name}
              </Typography>
              <Typography variant="subtitle1" overflow={"hidden"}>
                {(Number(product.averageRating) * 100) / 100}
                <span>
                  <Icon>
                    <Star color="warning" />
                  </Icon>
                </span>
              </Typography>
              <Typography color={AppColors.MainColor} fontWeight={"bold"}>
                {product.type === "variable" && "From "}
                {product.price.toFixed(2) + " " + props.currency}
              </Typography>
              <Button
                variant="outlined"
                onClick={goToProductPage}
                fullWidth
                sx={{
                  opacity: !product.isInStock ? 0.5 : 1,
                  ...styles.buyButton,
                }}
              >
                {!product.isInStock ? "Out of stock" : "Buy Now"}
              </Button>
            </CardContent>
          </div>
        </div>
      </Card>
      <Divider sx={styles.line} />
    </>
  );
};
