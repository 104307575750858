import { colors } from "@mui/material";
import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const checkoutPage: StyleModel = {
  mobile: {
    payment: {
      container: {
        mb: 2,
      },
      wrapper: {
        borderRadius: 1.5,
        bgcolor: AppColors.LightColor,
        boxShadow: "rgb(225 225 225 / 59%) -2px 1px 20px",
        mb: 2,
      },
      value: {
        textAlign: "right",
        fontWeight: "bold",
      },
      quantity: {
        color: AppColors.DarkGray,
      },
      total: {
        fontSize: "large",
      },
      paymentMethods: {
        m: 2
      },
      continueButton: {
        p: 2,
        mt: 2,
        fontSize: "large",
        fontWeight: "bolder",
        borderRadius: 1.5,
      },
      uploadRecipt: {
        wrapper: {
          border: "1px dotted " + AppColors.MainColor,
          borderRadius: "10px",
          marginTop: "10px",
          minHeight: "20vh",
          cursor: "pointer",
          overflow: "hidden"
        },
        label: {
          color: AppColors.MainColor,
        },
        content: {
          width: "100%",
          height: "100%",
          m: 0
        },
        preview: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
    },
  },
  desktop: {
    moveUp: {
      mt: -15,
      ml: 4,
      mb: 4,
      position: "relative",
    },
    payment: {
      uploadRecipt: {
        preview: {
          width: "20vw",
          margin: "auto"
        },
      },
    },
  },
};
