import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, setProfile } from "../reduxenv/Slices/ProfileSlice";
import AddSignatureButton from "../components/Auth/termsConditions/AddSignatureButton";
import { SignatureModel } from "../models/SignatureModel";
import { setIsLoading } from "../reduxenv/Slices/AppSlice";
import { useGetAgreementFilePathQuery } from "../services/AuthServices";
import { ResponseModel } from "../models/ResponseModel";
import { apiBaseURL, localStorageKeys, showToastMessage } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { AgreementFileParam } from "../models/AgreementFileParams";
import { useSubmitSignaturesMutation } from "../services/AppServices";
import { useNavigate } from "react-router-dom";

export const PendingSingaturePage: React.FC = () => {
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const [signatureModel1, setSignatureModel1] = useState<
    SignatureModel | undefined
  >(undefined);
  const [signatureModel2, setSignatureModel2] = useState<
    SignatureModel | undefined
  >(undefined);
  const [downloadAgreementAfterSubmit, setDownloadAgreementAfterSubmit] =
    useState(false);
  const [postSignatures] = useSubmitSignaturesMutation();
  const [getAgreementFileParams, setGetAgreementFileParams] =
    useState<AgreementFileParam>();
  const { data: agreementFilePath, isFetching: isFetchingAgreement } =
    useGetAgreementFilePathQuery(getAgreementFileParams!, {
      skip: !getAgreementFileParams,
    });

  // after getting the agreement file open in it in new window or try using PdfTron
  useEffect(() => {
    if (agreementFilePath && agreementFilePath.status === "success") {
      showToastMessage(
        "success",
        "Agreement file is being opened in another tab"
      );
      window.open(apiBaseURL + agreementFilePath.response.path, "_blank");
      setIsPendingSignaturesAndGoBack();
    }
  }, [agreementFilePath]);

  function setIsPendingSignaturesAndGoBack() {
    let tempProfile = { ...profile };
    tempProfile.isPendingSignatures = false;
    dispatch(setProfile(tempProfile));localStorage.setItem(
      localStorageKeys.profile,
      JSON.stringify(tempProfile)
    );
    LocalStorage.updateSignatureStatus(false);
    navigate("/profile");
  }

  async function submitSignatures() {
    if (signatureModel1 && signatureModel2) {
      dispatch(setIsLoading(true));
      let signatures = [
        {
          name: `${signatureModel1.name} (Role: ${signatureModel1.role})`,
          path: signatureModel1.fileModel?.path!,
        },
        {
          name: `${signatureModel2.name} (Role: ${signatureModel2.role})`,
          path: signatureModel2.fileModel?.path!,
        },
      ];
      postSignatures(signatures)
        .then((data: any) => {
          dispatch(setIsLoading(false));
          let response = data.data as ResponseModel;
          if (response.status === "success") {
            // This will trigger the Get AgreementFile call!
            if (downloadAgreementAfterSubmit) {
              setGetAgreementFileParams({
                countryId: profile.country.id,
                companyId: profile.companyId,
              });
            } else {
              setIsPendingSignaturesAndGoBack();
            }
            showToastMessage("success", response.message);
          }
        })
        .catch((error) => {
          dispatch(setIsLoading(false));
        });
    }
  }

  return (
    <>
      <Header />
      <Container maxWidth={"lg"}>
        <Grid container spacing={2} padding={2} justifyContent={"center"}>
          <Grid item lg={8} xs={12} md={12}>
            <Card sx={{ p: 2 }} variant="outlined">
              <CardContent>
                <Grid container spacing={2} justifyContent={"center"}>
                  <Typography variant="h4">
                    Review Terms & Conditions
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: profile.country.termsAndConditions,
                    }}
                  ></div>
                  <Grid
                    container
                    justifyContent={"space-around"}
                    mt={2}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">Signature*</Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={5} lg={4} mt={2}>
                      <AddSignatureButton
                        label={"Add Signature 1"}
                        signatureModel={signatureModel1}
                        setSignatureModel={setSignatureModel1}
                        removeFromStore={() => {}}
                        index={0}
                      />
                    </Grid>
                    <Grid item xs={5} lg={4} mt={2}>
                      <AddSignatureButton
                        label={"Add Signature 2"}
                        signatureModel={signatureModel2}
                        setSignatureModel={setSignatureModel2}
                        removeFromStore={() => {}}
                        index={1}
                      />
                    </Grid>
                    <Grid item xs={12} lg={10} mt={2}>
                      <Button
                        onClick={submitSignatures}
                        color="error"
                        variant="contained"
                        sx={{ borderRadius: 2, p: 2, fontWeight: "bold" }}
                        fullWidth
                        disabled={!signatureModel1 || !signatureModel2}
                      >
                        Agree & Submit
                      </Button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={downloadAgreementAfterSubmit}
                            onChange={(event) => {
                              setDownloadAgreementAfterSubmit(
                                event.target.checked
                              );
                            }}
                          />
                        }
                        label={"Download Agreement After Submiting"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
