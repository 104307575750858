import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

interface Props {
  cancel(): void;
  proceed(poNumber: string): void;
}

export const PoNumberField: React.FC<Props> = (props) => {
  const [poNumber, setPoNumber] = useState("");
  return (
    <>
      <DialogTitle>PO Number (optional)</DialogTitle>
      <DialogContent>
        <Typography>Enter the PO Number if available</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={poNumber}
          onChange={(e) => setPoNumber(e.target.value)}
          name="poNumber"
          placeholder="Enter the PO Number here.."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancel}>Skip</Button>
        <Button onClick={() => props.proceed(poNumber)}>Proceed</Button>
      </DialogActions>
    </>
  );
};
