import { Avatar, Card, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { AppColors } from "../../../common/AppColors";
import { ClearOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { DirectorModel } from "../../../models/DirectorModel";

interface Props {
  director: DirectorModel;
  removeDirector(index: number): void;
  showDirectorForm(director: DirectorModel): void;
  index: number;
}

export const DirectorCard: React.FC<Props> = (props) => {
  let director = props.director;

  function handleClickRemove(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    props.removeDirector(props.index);
  }

  return (
    <Grid lg={5} item>
      <div onClick={() => props.showDirectorForm(director)}>
        <Card
          sx={{
            p: 2,
            borderRadius: 2,
            background: AppColors.LightColor,
            color: AppColors.Blue,
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textTransform: "capitalize",
            borderColor: AppColors.DarkGray,
            cursor: "pointer",
            "&:hover": {
              background: AppColors.LightGray,
              borderColor: AppColors.Blue,
            },
          }}
        >
          <div>
            <Typography variant="button">{director.name}</Typography>
            <Typography variant="body2">{director.email}</Typography>
          </div>
          <IconButton
            sx={{
              width: 20,
              height: 20,
            }}
            onClick={handleClickRemove}
          >
            <Avatar
              sx={{
                width: 20,
                height: 20,
                bgcolor: red[500],
              }}
            >
              <ClearOutlined fontSize="small" />
            </Avatar>
          </IconButton>
        </Card>
      </div>
    </Grid>
  );
};
