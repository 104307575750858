import React, { useEffect, useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

interface Props {
  imageUrls: string[];
}

export const ProductImages: React.FC<Props> = (props) => {
  const [items, setItems] = useState<ReactImageGalleryItem[]>([]);

  useEffect(() => {
    generateItems();
  }, []);

  function generateItems() {
    let items: any = [];
    props.imageUrls.forEach((url) => {
      let item = {
        original: url,
        thumbnail: url,
      };
      items.push(item);
    });
    setItems(items);
  }
  return (
    <ImageGallery
      items={items}
      showFullscreenButton={false}
      showPlayButton={false}
      showThumbnails={true}
      showNav={false}
      slideDuration={500}
      additionalClass="product-images"
    />
  );
};
