import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";

export const dashboardAPI = createApi({
  reducerPath: "dashboardAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL + "dashboard/",
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllOrders: builder.query<ResponseModel, void>({
      query: () => "orders",
      keepUnusedDataFor: 0.0001,
    }),
    getTotalDue: builder.query<ResponseModel, void>({
      query: () => "totaldue",
      keepUnusedDataFor: 0.0001,
    }),
  }),
});

export const { useGetAllOrdersQuery, useGetTotalDueQuery } = dashboardAPI;
