import {
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppColors } from "../../common/AppColors";
import { ControlledTextField } from "../shared/ControlledTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EndUserSchema } from "../../schemas/EndUserSchema";
import {
  useAssignEndUserMutation,
  useEditEndUserMutation,
  useGetAllReminderTypesQuery,
  useGetEndUserByDigitalKeyIdQuery,
  useRemoveEndUserMutation,
} from "../../services/MyOrderServices";
import { EndUserModel } from "../../models/EndUserModel";
import { ReminderTypeModel } from "../../models/ReminderTypeModel";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import useConfirm from "../../hooks/useConfirm";
import { showToastMessage } from "../../common/Helper";

interface Props {
  digital_key_id: number;
  handleClose(): void;
}

export const EndUser: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [endUser, setEndUser] = useState<EndUserModel>();
  const [reminderTypes, setReminderTypes] = useState<ReminderTypeModel[]>();
  const [selectedReminderTypeIds, setSelectedReminderTypeIds] = useState<
    number[]
  >([]);
  const isEdit = !!endUser && !!endUser.id;

  const { data: endUserResponse } = useGetEndUserByDigitalKeyIdQuery(
    props.digital_key_id,
    {
      skip: !props.digital_key_id,
    }
  );
  const { data: reminderTypeResponse } = useGetAllReminderTypesQuery();
  const [assignEndUser] = useAssignEndUserMutation();
  const [editEndUser] = useEditEndUserMutation();
  const [removeEndUser] = useRemoveEndUserMutation();
  const [RemoveConfirmDialog, confirmRemove] = useConfirm(
    "Warning",
    "Are you sure, do you want to remove this end-user?"
  );

  const {
    handleSubmit,
    control,
    setValue,
    formState: errors,
  } = useForm<EndUserModel>({
    resolver: yupResolver(EndUserSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
  });

  useEffect(() => {
    if (endUserResponse && endUserResponse.status === "success") {
      let tempEndUser = endUserResponse.response as EndUserModel;
      if (tempEndUser) {
        let tempSelectedTypeIds = tempEndUser.end_user_reminder_types!.map(
          (x) => x.reminder_type_id
        );
        setSelectedReminderTypeIds(tempSelectedTypeIds);
        setValue("first_name", tempEndUser.first_name);
        setValue("last_name", tempEndUser.last_name);
        setValue("email", tempEndUser.email);
      }
      setEndUser(tempEndUser);
    }
  }, [endUserResponse]);

  useEffect(() => {
    if (reminderTypeResponse && reminderTypeResponse.status === "success") {
      let tempTypes = reminderTypeResponse.response as ReminderTypeModel[];
      setReminderTypes(tempTypes);
    }
  }, [reminderTypeResponse]);

  async function submit(data: EndUserModel) {
    dispatch(setIsLoading(true));
    try {
      data.end_user_reminder_types = selectedReminderTypeIds.map((x) => ({
        reminder_type_id: x,
      }));
      data.digital_key_id = props.digital_key_id!;
      if (isEdit) {
        data.id = endUser.id;
        await editEndUser(data);
      } else {
        await assignEndUser(data);
      }
      showToastMessage(
        "success",
        `End-User ${isEdit ? "modified" : "added"} successfully!`
      );
    } catch {
      showToastMessage("error", "An error occurred!");
    }
    dispatch(setIsLoading(false));
    props.handleClose();
  }

  function handleCheckReminderType(
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    let tempTypeId = Number(e.target.value);
    let tempSelectedReminderTypeIds = [...selectedReminderTypeIds];
    if (checked) {
      tempSelectedReminderTypeIds.push(tempTypeId);
    } else {
      tempSelectedReminderTypeIds = tempSelectedReminderTypeIds.filter(
        (x) => x !== tempTypeId
      );
    }
    setSelectedReminderTypeIds(tempSelectedReminderTypeIds);
  }

  async function handleRemove() {
    if (await confirmRemove()) {
      dispatch(setIsLoading(true));
      try {
        await removeEndUser(endUser?.id!);
        showToastMessage("success", "End-User removed successfully!");
      } catch {
        showToastMessage("error", "An error occurred!");
      }
      dispatch(setIsLoading(false));
      props.handleClose();
    }
  }

  return (
    <>
      <DialogTitle color={AppColors.MainColor} textAlign={"center"}>
        Add End-User to the digital key
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          component={"form"}
          spacing={2}
          onSubmit={handleSubmit(submit, (e) => {
            console.log(e);
          })}
        >
          <Grid item xs={12}>
            <ControlledTextField
              name="first_name"
              label="First Name"
              control={control}
              error={errors.errors.first_name}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="last_name"
              label="Last Name"
              control={control}
              error={errors.errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="email"
              label="Email"
              control={control}
              error={errors.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Typography variant="subtitle1">
              Reminder Interval Options
            </Typography>
            <Stack
              direction={"row"}
              border={"2px solid " + AppColors.Blue}
              mt={1}
              borderRadius={2}
              p={2}
            >
              {reminderTypes?.map((type) => (
                <label key={type.id}>
                  {type.name}
                  <Checkbox
                    value={type.id}
                    checked={selectedReminderTypeIds?.includes(type.id)}
                    onChange={handleCheckReminderType}
                  />
                </label>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"right"} spacing={2}>
              <Button variant="contained" type="submit">
                {isEdit ? "Update" : "Save"}
              </Button>
              {isEdit && (
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              )}
              <Button
                variant="outlined"
                type="button"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <RemoveConfirmDialog />
    </>
  );
};
