import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllDigitalKeysQuery } from "../../services/MyOrderServices";
import { AppColors } from "../../common/AppColors";
import { CopyAllRounded, Person2 } from "@mui/icons-material";
import { copyToClipboard } from "../../common/Helper";
import { EndUser } from "./EndUser";

interface Props {
  orderId: number;
  productId: number;
}

type DigitalKeyModel = {
  id: number;
  key: string;
};

export const DigitalKeysList: React.FC<Props> = (props) => {
  const { data: digitalKeysResponse, isLoading } = useGetAllDigitalKeysQuery({
    orderId: props.orderId,
    productId: props.productId,
  });
  const [keys, setKeys] = useState<DigitalKeyModel[]>();
  const [selectedDigitalKey, setSelectedDigitalKey] = useState<number>();

  useEffect(() => {
    if (digitalKeysResponse && digitalKeysResponse.status === "success") {
      let tempKeys = digitalKeysResponse.response2 as DigitalKeyModel[];
      if (tempKeys) {
        setKeys(tempKeys);
      }
    }
  }, [digitalKeysResponse]);

  function closeEndUserDialog() {
    setSelectedDigitalKey(undefined);
  }

  return (
    <>
      <DialogTitle color={AppColors.MainColor} textAlign={"center"}>
        Digital Keys
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading ? (
          <Skeleton variant={"text"} />
        ) : (
          <>
            {keys && keys.length > 0 ? (
              <List>
                {keys.map((key, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={key.key} />
                    <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          setSelectedDigitalKey(key.id);
                        }}
                      >
                        <Tooltip title={"Add End-User"}>
                          <Person2 />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          copyToClipboard(key.key);
                        }}
                      >
                        <CopyAllRounded />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                color={AppColors.MainColor}
                bgcolor={AppColors.LightColor}
                p={4}
                textAlign={"center"}
                borderRadius={1.5}
              >
                No Keys Found!
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <Dialog open={!!selectedDigitalKey} onClose={closeEndUserDialog}>
        <EndUser
          handleClose={closeEndUserDialog}
          digital_key_id={selectedDigitalKey!}
        />
      </Dialog>
    </>
  );
};
