import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ControlledTextField } from "../../shared/ControlledTextField";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DirectorModel } from "../../../models/DirectorModel";
import { DirectorFormSchema } from "../../../schemas/DirectorFormSchema";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DirectorshipForm from "./DirectorshipForm";
import { DirectorshipModel } from "../../../models/DirectorShipModel";
import { AddRounded } from "@mui/icons-material";
import dayjs from "dayjs";

interface Props {
  director?: DirectorModel;
  handleAddDirector(director: DirectorModel): void;
}

export const DirectorFormPopup: React.FC<Props> = (props) => {
  let director = props.director;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DirectorModel>({
    resolver: yupResolver(DirectorFormSchema),
    defaultValues: director
      ? director
      : {
          name: "",
          dob: "",
          houseNumber: "",
          streetName: "",
          state: "",
          phone: "",
          email: "",
          bankrupt: false,
          pastDirectorships: [],
        },
  });
  const [showAddDirectorship, setShowAddDirectorship] = useState(false);
  const [tempPastDirectorships, setTempPastDirectorships] = useState<
    DirectorshipModel[]
  >(
    director
      ? director.pastDirectorships
        ? director.pastDirectorships
        : []
      : []
  );

  async function submit(directorModel: DirectorModel) {
    directorModel.pastDirectorships = tempPastDirectorships;
    directorModel.address =
      directorModel.houseNumber +
      " " +
      directorModel.streetName +
      " " +
      directorModel.state;
    props.handleAddDirector(directorModel);
  }

  function handleAddTempDirectorship(directorship: DirectorshipModel) {
    let allTempDirectorships = [...tempPastDirectorships];
    allTempDirectorships.push(directorship);
    setTempPastDirectorships(allTempDirectorships);
    setShowAddDirectorship(false);
  }

  function removeTempPastDirectorship(index: number) {
    let filteredDirectorships = [...tempPastDirectorships];
    filteredDirectorships.splice(index, 1);
    setTempPastDirectorships(filteredDirectorships);
  }

  function handleCheckDirectorship(_event: any, checked: boolean) {
    if (!checked) {
      setTempPastDirectorships([]);
    }
    setShowAddDirectorship(checked);
  }

  return (
    <>
      <DialogTitle variant="h4">Add Director</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          component={"form"}
          onSubmit={handleSubmit(submit)}
        >
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="name"
              label={"Full Name*"}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="dob"
              render={({ field }) => (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(field.value)}
                      onChange={(date) => field.onChange(date)}
                      label={"Date of Birth*"}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
            {errors.dob && (
              <FormHelperText error>{errors.dob.message}</FormHelperText>
            )}
          </Grid>
          <Grid container mt={2}>
            <Grid item lg={3} ml={2} xs={12}>
              <ControlledTextField
                control={control}
                name="houseNumber"
                label={"House Number*"}
                error={errors.houseNumber}
              />
            </Grid>
            <Grid
              item
              lg={6}
              ml={{ lg: 5, xs: 2 }}
              xs={12}
              mt={{ lg: 0, xs: 2 }}
            >
              <ControlledTextField
                control={control}
                name="streetName"
                label={"Street Name*"}
                error={errors.streetName}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="state"
              label={"State*"}
              error={errors.state}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="phone"
              label={"Phone*"}
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="email"
              label={"Email Address*"}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"} mt={4}>
              <Typography variant="subtitle2">
                Have this Director / Proprietor / Partner been declared bankrupt
                or involved with any insolvency?
              </Typography>
              <Controller
                name="bankrupt"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    sx={{ ml: "auto" }}
                  />
                )}
              />
            </Stack>
            {errors.bankrupt && (
              <FormHelperText error>{errors.bankrupt.message}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"} mt={4}>
              <Typography variant="subtitle2">
                Other Directorships Held during the Past 5 Years?
              </Typography>
              <Checkbox
                onChange={handleCheckDirectorship}
                checked={tempPastDirectorships.length > 0}
                sx={{ ml: "auto" }}
              />
            </Stack>
          </Grid>
          {tempPastDirectorships.length > 0 && (
            <>
              {tempPastDirectorships.map((director, index) => (
                <Grid item lg={10} xs={12} key={index}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography>{director.name}</Typography>
                    <Button
                      variant="text"
                      onClick={() => removeTempPastDirectorship(index)}
                    >
                      Remove
                    </Button>
                  </Stack>
                </Grid>
              ))}
              <Grid item lg={10} textAlign={"right"}>
                <Typography variant="caption" mr={1}>
                  Click "+" button to add more Directorships
                </Typography>
                <IconButton onClick={() => setShowAddDirectorship(true)}>
                  <Icon>
                    <AddRounded />
                  </Icon>
                </IconButton>
              </Grid>
            </>
          )}
          <Grid item xs={12} mt={2} sx={{ position: "sticky", bottom: 0 }}>
            <Button
              sx={{ p: 2, fontWeight: "bold", borderRadius: 2 }}
              type="submit"
              fullWidth
              variant="contained"
            >
              {director ? "Save" : "Add"} Director
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={showAddDirectorship}
          maxWidth={"xs"}
          onClose={() => setShowAddDirectorship(false)}
        >
          <DialogContent>
            <DirectorshipForm
              handleAddDirectorship={handleAddTempDirectorship}
              handleClose={() => setShowAddDirectorship(false)}
            />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </>
  );
};
