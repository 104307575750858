import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";
import { showToastMessage } from "../common/Helper";

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    showToastMessage("error", action.payload.data.message);
    
  }
  return next(action);
};
