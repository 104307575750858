import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

const loaderElements: any = [];
for (let i = 0; i < 8; i++) {
  let element = (
    <Box
      key={i}
      sx={{
        display: "inline-block",
        borderRadius: 2,
        m: 1,
        width: { xs: "45%", lg: "23%" },
      }}
    >
      <div>
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="text" width={"80%"} sx={{ ml: 2 }} />
        <Skeleton variant="text" width={"70%"} sx={{ ml: 4 }} />
      </div>
    </Box>
  );
  loaderElements.push(element);
}

export const ProductsLoader: React.FC = () => {
  return <Grid container>{loaderElements}</Grid>;
};
