import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import React, { useState } from "react";

interface Props {
  cancel(): void;
  proceed(quantity: number): void;
}

export const StockBackQuantityField: React.FC<Props> = (props) => {
  const [quantity, setQuantity] = useState(1);

  return (
    <>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Typography>Enter the Quantity</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
          name="qunaity"
          type="number"
          placeholder="Enter the Quantity here.."
          error={quantity < 1}
        />
        {quantity < 1 && (
          <FormHelperText error>Quantity can not be less than 1</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={quantity < 1} onClick={() => props.proceed(quantity)}>
          Proceed
        </Button>
      </DialogActions>
    </>
  );
};
