import { createSlice } from "@reduxjs/toolkit";
import { localStorageKeys } from "../../common/Helper";
import { DirectorModel } from "../../models/DirectorModel";

function checkDirectorsFormFilled() {
    let directors: DirectorModel[] = [];
    let directorsString = localStorage.getItem(localStorageKeys.directors);
    if (directorsString) {
        directors = JSON.parse(directorsString);
    }
    return directors;
}

const directors = createSlice({
    name: "directors",
    initialState: {
        value: checkDirectorsFormFilled(),
    },
    reducers: {
        setDirectors: (state, action: { payload: DirectorModel[] }) => {
            state.value = action.payload;
        },
    },
});

export const { setDirectors } = directors.actions;
export const getDirectors = (state: any) =>
    state.directors.value as DirectorModel[];
export default directors.reducer;
