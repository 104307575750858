import {
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
  colors,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DirectorCard } from "./DirectorCard";
import { AppColors } from "../../../common/AppColors";
import { AddRounded, DoubleArrow, SaveRounded } from "@mui/icons-material";
import { DirectorFormPopup } from "./DirectorFormPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  getDirectors,
  setDirectors,
} from "../../../reduxenv/Slices/DirectorsSlice";
import { DirectorModel } from "../../../models/DirectorModel";
import { localStorageKeys } from "../../../common/Helper";
import { RegistrationStepIndexes } from "../../../common/Enums";

interface Props {
  handleGoToNextStep(nextStepIndex: number): void;
}

export const DirectorsDetails: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const directors = useSelector(getDirectors);
  const [isShowDirectorForm, setIsShowDirectorForm] = useState(false);
  const [selectedDirector, setSelectedDirector] = useState<DirectorModel>();

  useEffect(() => {
    if (localStorage.getItem(localStorageKeys.directors) === "") {
      props.handleGoToNextStep(RegistrationStepIndexes.Terms);
    }
  }, []);

  function handleClickAddNewDirector() {
    setSelectedDirector(undefined);
    setIsShowDirectorForm(true);
  }

  function handleShowDirectorForm(director: DirectorModel) {
    setSelectedDirector(director);
    setIsShowDirectorForm(true);
  }

  function removeDirector(index: number) {
    let allDirectors = [...directors];
    allDirectors.splice(index, 1);
    dispatch(setDirectors(allDirectors));
  }

  function handleAddDirector(director: DirectorModel) {
    let tempDirectors = [...directors];
    if (selectedDirector) {
      let selectedDirectorIndex = tempDirectors.findIndex(
        (x) => x === selectedDirector
      );
      tempDirectors[selectedDirectorIndex] = director;
    } else {
      if (!tempDirectors.some((x) => x === director)) {
        tempDirectors.push(director);
      }
    }
    dispatch(setDirectors(tempDirectors));
    setIsShowDirectorForm(false);
  }

  async function handleClickSave() {
    await localStorage.setItem(
      localStorageKeys.directors,
      JSON.stringify(directors)
    );
    props.handleGoToNextStep(RegistrationStepIndexes.Suppliers);
  }

  async function handleClickSkip() {
    await localStorage.setItem(localStorageKeys.directors, "");
    props.handleGoToNextStep(RegistrationStepIndexes.Suppliers);
  }

  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <CardContent>
        <Typography mt={2} variant="h4" color={AppColors.Blue}>
          Directors Details
          <Button
            onClick={handleClickSkip}
            color="warning"
            variant="outlined"
            sx={{
              display: "flex",
              ml: "auto",
              float: "right",
              fontWeight: "bold",
              color: colors.red[500],
            }}
            endIcon={<DoubleArrow />}
          >
            Skip
          </Button>
        </Typography>
        <Divider />
        <Grid container mt={2} spacing={2} justifyContent={"center"}>
          {directors &&
            directors.map((director, index) => (
              <DirectorCard
                key={index}
                index={index}
                director={director}
                removeDirector={removeDirector}
                showDirectorForm={handleShowDirectorForm}
              />
            ))}
          <Grid
            item
            lg={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton onClick={handleClickAddNewDirector}>
              <AddRounded />
            </IconButton>
          </Grid>
        </Grid>
        {directors.length > 0 ? (
          <Button
            onClick={handleClickSave}
            sx={{ mt: 2, pt: 1, pl: 4, pr: 4, ml: "auto", display: "flex" }}
            color="success"
            variant="contained"
            endIcon={<SaveRounded />}
          >
            SAVE
          </Button>
        ) : (
          <Typography
            textAlign={"center"}
            color={AppColors.DarkGray}
            variant="subtitle1"
          >
            Click '+' to Add a Director
          </Typography>
        )}
      </CardContent>
      <Dialog
        open={isShowDirectorForm}
        fullWidth
        onClose={() => {
          setIsShowDirectorForm(false);
        }}
      >
        <DirectorFormPopup
          director={selectedDirector}
          handleAddDirector={handleAddDirector}
        />
      </Dialog>
    </Card>
  );
};
