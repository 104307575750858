import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import { DirectorshipModel } from "../../../models/DirectorShipModel";
import { DirectorshipSchema } from "../../../schemas/DirectorshipSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ControlledTextField } from "../../shared/ControlledTextField";
import { useSelector } from "react-redux";
import { getSelectedCountry } from "../../../reduxenv/Slices/SelectedCountrySlice";

interface Props {
  handleAddDirectorship(directorshipModel: DirectorshipModel): void;
  handleClose(): void;
}

const DirectorshipForm: React.FC<Props> = (props) => {
  const country = useSelector(getSelectedCountry);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DirectorshipModel>({
    resolver: yupResolver(DirectorshipSchema),
    defaultValues: {
      name: "",
      taxNo: "",
      regNo: "",
      isPublicCompany: false,
    },
  });

  function submit(directorModel: DirectorshipModel) {
    props.handleAddDirectorship(directorModel);
  }

  return (
    <Grid
      mt={1}
      container
      spacing={1}
      component={"form"}
      onSubmit={handleSubmit(submit)}
    >
      <Grid item xs={12}>
        <Typography variant="h5" textAlign={"center"}>
          Add Directorship Details
        </Typography>
        <ControlledTextField
          control={control}
          name="name"
          label={"Company Name*"}
          error={errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name="regNo"
          label={country.regNoTerm + "*"}
          error={errors.regNo}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name="taxNo"
          label={
            country &&
            (country.taxNoTerm !== "" ? country.taxNoTerm : "TAX Number") + "*"
          }
          error={errors.taxNo}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name="isPublicCompany"
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
          }
          label="It's a Public Company"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Button type="button" variant="outlined" fullWidth onClick={props.handleClose}>
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} md={7}>
        <Button type="submit" variant="contained" fullWidth>
          Add past Directorship
        </Button>
      </Grid>
    </Grid>
  );
};

export default DirectorshipForm;
