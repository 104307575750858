import { Button, Card, CardContent } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";

interface Props {
  callback(status: boolean): void;
}

export const StripeCheckoutForm: React.FC<Props> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    dispatch(setIsLoading(true));
    stripe
      .confirmPayment({ elements, redirect: "if_required" })
      .then((response) => {
        props.callback(response.paymentIntent?.status === "succeeded");
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  };
  return (
    <Card>
      <CardContent component={"form"} onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          disabled={!stripe || !elements}
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
          type="submit"
        >
          Pay Now
        </Button>
      </CardContent>
    </Card>
  );
};
