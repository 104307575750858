import { createSlice } from "@reduxjs/toolkit";
import { localStorageKeys } from "../../common/Helper";
import { SupplierModel } from "../../models/SupplierModel";

function checkSupplierFormFilled() {
    let supplier: SupplierModel[] = [];
    let suppliersString = localStorage.getItem(localStorageKeys.suppliers);
    if (suppliersString) {
        supplier = JSON.parse(suppliersString);
    }
    return supplier;
}

const supplier = createSlice({
    name: "suppliers",
    initialState: {
        value: checkSupplierFormFilled(),
    },
    reducers: {
        setSuppliers: (state, action: { payload: SupplierModel[] }) => {
            state.value = action.payload;
        },
    },
});

export const { setSuppliers } = supplier.actions;
export const getSuppliers = (state: any) =>
    state.suppliers.value as SupplierModel[];
export default supplier.reducer;
