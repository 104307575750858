import { colors } from "@mui/material";
import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const productTrackingPage: StyleModel = {
  mobile: {
    deliveryStatus: {
      color: AppColors.Blue,
      bgcolor: AppColors.LightColor,
      borderRadius: 3,
      border: "1px solid " + AppColors.White,
      width: "100%",
      padding: "20px 10%"
    },
    copyId: {
      ml: 1,
      color: AppColors.White,
      "&:hover": {
        color: colors.red[400],
      },
    },
    deliveryDate: {
      bgcolor: colors.green[500],
      color: AppColors.White,
      p: 3,
      borderRadius: 2,
      border: "1px solid " + AppColors.DarkGray,
      boxShadow: "5px 5px 20px #00000029",
    },
    total: {
      bgcolor: colors.blue[500],
      color: AppColors.White,
      p: 3,
      borderRadius: 2,
      border: "1px solid " + AppColors.DarkGray,
      boxShadow: "5px 5px 20px #00000029",
    },
  },
  desktop: {
  },
};
