import * as yup from "yup";

export const ResetPasswordFormSchema = yup.object().shape({
  password: yup.string().min(8).max(20).required().label("Password"),
  confirmPassword: yup
    .string()
    .min(8)
    .max(20)
    .required()
    .label("Confirm Password")
    .oneOf([yup.ref("password")], "Password doesn't match"),
});
