import { useNavigate } from "react-router-dom";

const useErrorPage = () => {
  const navigate = useNavigate();

  const toErrorPage = (error: any) => {
    navigate("/error/errorindex", { state: { error: error.message } });
  };

  return [toErrorPage];
};

export default useErrorPage;
