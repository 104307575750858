import React, { useEffect, useState } from "react";
import { useGetAllRefundHistoryQuery } from "../../services/MyOrderServices";
import { RefundModel } from "../../models/RefundModel";
import { snakeToCamel } from "../../common/Helper";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { AppColors } from "../../common/AppColors";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";

interface Props {
  orderId: number;
}

export const RefundHistoryList: React.FC<Props> = (props) => {
  const { formatCurrency } = useFormatCurrency();
  const { data: refundHistoryReponse } = useGetAllRefundHistoryQuery(
    props.orderId
  );
  const [refundList, setRefundList] = useState<RefundModel[]>([]);

  useEffect(() => {
    if (refundHistoryReponse && refundHistoryReponse.status === "success") {
      let tempRefundList = snakeToCamel(
        refundHistoryReponse.response
      ) as RefundModel[];
      setRefundList(tempRefundList);
    }
  }, [refundHistoryReponse]);

  return (
    <List>
      <Typography variant="h5">Refund History</Typography>
      {refundList.map((refund, index) => (
        <ListItem>
          <ListItemText primary={refund.datetime} secondary={refund.reason} />
          <ListItemText
            primary={"-" + formatCurrency(refund.amount)}
            primaryTypographyProps={{
              color: AppColors.MainColor,
              fontWeight: "bold",
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};
