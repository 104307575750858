import { StyleModel } from "../models/StyleModel";

export const homePage: StyleModel = {
  mobile: {
    search: {
      position: "absolute",
      bottom: "10px",
      left: "0",
      right: "0",
      pt: 2,
      pb: 2,
      pl: 5,
      pr: 5,
      width: "70vw",
      margin: "auto",
      boxShadow: "#06060633 0px 0px 20px 9px",
      borderRadius: 10,
      maxWidth: "720px",
    },
  },
  desktop: {
    search: {
      pt: 3,
      pb: 2.8,
      width: "50vw",
      bottom: "-30px",
    },
  },
};
