import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { appTitle, snakeToCamel } from "../common/Helper";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Wallpaper } from "../components/shared/Wallpaper";
import { StyleContext } from "../context/StyleContextProvider";
import {
  Container,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  useDeleteFromWishlistMutation,
  useGetAllWishlistItemsQuery,
} from "../services/WishlistServices";
import { ProductModel } from "../models/ProductModel";
import { LongProductCard } from "../components/shared/LongProductCard";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../reduxenv/Slices/AppSlice";
import { FavoriteOutlined } from "@mui/icons-material";
import { WishlistLoader } from "../components/Loaders/WishlistLoader";

export const WishlistPage: React.FC = () => {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("wishlistPage")
  );
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 786px)");
  const {
    data: wishlistItemsResponse,
    refetch,
    isLoading,
  } = useGetAllWishlistItemsQuery();
  const [deleteFromWishlist] = useDeleteFromWishlistMutation();
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("wishlistPage"));
  }, [isMobile]);

  useEffect(() => {
    if (wishlistItemsResponse && wishlistItemsResponse.status === "success") {
      let tempProducts = snakeToCamel(
        wishlistItemsResponse.response
      ) as ProductModel[];
      setProducts(tempProducts);
    }
  }, [wishlistItemsResponse]);

  function deleteItemFromWishlist(parentId: number, productId: number) {
    dispatch(setIsLoading(true));
    deleteFromWishlist({ parentId: parentId, productId: productId })
      .then(() => {
        dispatch(setIsLoading(false));
        refetch();
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  }

  return (
    <>
      <Helmet>
        <title>{appTitle + " - My Favourites"}</title>
      </Helmet>
      <Header />
      <Wallpaper
        content={
          <Typography variant="h5" sx={styles.title}>
            My Favourites
            <Icon sx={styles.favIcon}>
              <FavoriteOutlined />
            </Icon>
          </Typography>
        }
      />
      <Container maxWidth={"md"}>
        <Grid
          container
          sx={{
            ...styles.wrapper,
            ...(!isLoading && products.length === 0 && styles.alignCenter),
          }}
        >
          {isLoading ? (
            <WishlistLoader />
          ) : (
            <>
              {products.length > 0 ? (
                products.map((product, index) => (
                  <Grid item xs={12} key={index}>
                    <LongProductCard
                      styles={styles.card}
                      product={product}
                      currency="AUD"
                      handleClickDelete={deleteItemFromWishlist}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h5" sx={styles.wishlistEmpty}>
                    Nothing in My Favourites!
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
