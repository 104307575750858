import {
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SupplierCard } from "./SupplierCard";
import { AppColors } from "../../../common/AppColors";
import { AddRounded, SaveRounded } from "@mui/icons-material";
import { SupplierFormPopup } from "./SupplierFormPopup";
import { useDispatch, useSelector } from "react-redux";
import { localStorageKeys } from "../../../common/Helper";
import {
  getSuppliers,
  setSuppliers,
} from "../../../reduxenv/Slices/SupplierSlice";
import { SupplierModel } from "../../../models/SupplierModel";
import { RegistrationStepIndexes } from "../../../common/Enums";

interface Props {
  handleGoToNextStep(nextStepIndex: number): void;
  fromProfilePage?: boolean;
}

export const SupplierDetails: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const suppliers = useSelector(getSuppliers);
  const [isShowSupplierForm, setIsShowSupplierForm] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierModel>();

  useEffect(() => {
    if (localStorage.getItem(localStorageKeys.suppliers) === "") {
      props.handleGoToNextStep(RegistrationStepIndexes.Terms);
    }
  }, []);

  function handleClickShowSupplierForm() {
    setSelectedSupplier(undefined);
    setIsShowSupplierForm(true);
  }

  function handleShowSupplierForm(supplier: SupplierModel) {
    setSelectedSupplier(supplier);
    setIsShowSupplierForm(true);
  }

  function removeSupplier(index: number) {
    let allSuppliers = [...suppliers];
    allSuppliers.splice(index, 1);
    dispatch(setSuppliers(allSuppliers));
  }

  function handleAddSupplier(supplier: SupplierModel) {
    let tempSuppliers = [...suppliers];
    if (selectedSupplier) {
      let selectedSupplierIndex = tempSuppliers.findIndex(
        (x) => x === selectedSupplier
      );
      tempSuppliers[selectedSupplierIndex] = supplier;
    } else {
      if (!tempSuppliers.some((x) => x === supplier)) {
        tempSuppliers.push(supplier);
      }
    }
    dispatch(setSuppliers(tempSuppliers));
    setIsShowSupplierForm(false);
  }

  async function handleClickSave() {
    await localStorage.setItem(
      localStorageKeys.suppliers,
      JSON.stringify(suppliers)
    );
    props.handleGoToNextStep(RegistrationStepIndexes.Terms);
  }

  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <CardContent>
        <Typography mt={2} variant="h4" color={AppColors.Blue}>
          Suppliers Details
        </Typography>
        <Divider />
        <Grid container mt={2} spacing={2} justifyContent={"center"}>
          {suppliers &&
            suppliers.map((supplier, index) => (
              <SupplierCard
                key={index}
                index={index}
                supplier={supplier}
                removeSupplier={removeSupplier}
                showSupplierForm={handleShowSupplierForm}
              />
            ))}
          <Grid
            item
            lg={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton
              onClick={handleClickShowSupplierForm}
              disabled={suppliers.length === 3}
            >
              <AddRounded />
            </IconButton>
          </Grid>
        </Grid>
        {suppliers.length > 0 ? (
          <Button
            onClick={handleClickSave}
            sx={{ mt: 2, pt: 1, pl: 4, pr: 4, ml: "auto", display: "flex" }}
            color="success"
            variant="contained"
            endIcon={<SaveRounded />}
          >
            {props.fromProfilePage ? "Submit " : "SAVE"}
          </Button>
        ) : (
          <Typography
            textAlign={"center"}
            color={AppColors.DarkGray}
            variant="subtitle1"
          >
            Click '+' to Add a Supplier
          </Typography>
        )}
      </CardContent>
      <Dialog
        open={isShowSupplierForm}
        fullWidth
        onClose={() => setIsShowSupplierForm(false)}
      >
        <SupplierFormPopup
          supplier={selectedSupplier}
          handleAddSupplier={handleAddSupplier}
        />
      </Dialog>
    </Card>
  );
};
