import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const profilePage: StyleModel = {
  mobile: {
    wallpaperContent: {
      position: "absolute",
      bottom: "10px",
      color: AppColors.White,
    },
    imageWrapper: {
      width: 150,
      height: 150,
      bgcolor: AppColors.LightColor,
      boxShadow: "5px 5px 20px #00000029",
      margin: "auto",
      mt: 4,
    },
    logoutButton: {
      mt: 1,
      display: {
        xs: "none",
        lg: "block",
      },
    },
    logoutButtonMobile: {
      mt: 1,
      display: {
        xs: "block",
        lg: "none",
      },
    },
    accordianButton: {
      left: {
        border: "1px solid " + AppColors.MainColor,
      },
      right: {
        border: "1px solid " + AppColors.MainColor,
        mt: {
          xs: 1,
          lg: 0,
        },
      },
      selected: {
        bgcolor: AppColors.MainColor,
        color: AppColors.White,
        fontWeight: "bold",
        "&: hover": {
          color: AppColors.White,
          bgcolor: AppColors.MainColor,
        },
      },
    },
    contentButtonWrapper: {
      margin: "auto",
    },
    contentWrapper: {
      mb: 2,
    },
    manageReseller: {
      textTransform: "capitalize",
      display: "block",
      margin: "auto",
      mt: 2,
      width: "93%",
    },
  },
  desktop: {
    imageWrapper: {
      width: "100%",
      height: "200px",  
      borderRadius: "0.52vw",
      mt: 0,
    },
    imageContainer: {
      mt: "-8vw",
    },
    contentWrapper: {
      mr: 4,
      mt: 2
    },
    contentButtonWrapper: {
      display: "flex",
      width: "70%",
    },
    accordianButton: {
      left: {
        borderRadius: "0.52vw 0 0 0.52vw",
      },
      right: {
        borderRadius: "0 0.52vw 0.52vw 0",
      },
      selected: {},
    },
    manageReseller: {
      mt: 2,
      width: "100%",
    },
  },
};
