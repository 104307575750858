import { Card, Grid, Icon, Typography } from "@mui/material";
import React from "react";
import { AppColors } from "../../common/AppColors";
import { useNavigate } from "react-router-dom";

interface Props {
  linkModel: NavLinkModel;
}

export type NavLinkModel = {
  title: string;
  link: string;
  icon: any;
  bgColor: string;
  image: string;
};

export const NavigationLink: React.FC<Props> = (props) => {
  let linkModel = props.linkModel;
  const navigate = useNavigate();

  function handleLinkClick() {
    navigate(linkModel.link);
  }
  return (
    <Grid item xs={12} md={3} lg={2}>
      <Card
        variant="outlined"
        sx={{
          display: "inline-flex",
          border: "none",
          borderRadius: 3,
          m: {xs: 0, lg: 1},
          "&: hover": {
            boxShadow: "5px 5px 20px #00000029",
          },
          cursor: "pointer",
          bgcolor: linkModel.bgColor,
          width: "100%",
          height: {xs: "20vw", lg: "12vw"},
        }}
      >
        <div
          onClick={handleLinkClick}
          style={{
            textAlign: "center",
            position: "relative",
            width: "100%",
            padding: "10px",
          }}
          className="center-content"
        >
          <Icon
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
              bgcolor: AppColors.White,
              color: linkModel.bgColor,
              borderRadius: "50%",
              p: "4px",
            }}
          >
            <linkModel.icon sx={{ color: linkModel.bgColor }} />
          </Icon>
          <Typography variant="h5" color={AppColors.White} textAlign={"center"}>
            {linkModel.title}
          </Typography>
        </div>
      </Card>
    </Grid>
  );
};
