import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { StripeCheckoutForm } from "./StripeCheckoutForm";
import { StripeInitModel } from "../../models/StripeInitModel";

interface Props {
  initData: StripeInitModel;
  callback(status: boolean): void;
}

export const StripeContainer: React.FC<Props> = (props) => {
  let initData = props.initData;
  const [stripeInstance] = useState<
    Promise<Stripe | null> | undefined
  >(loadStripe(initData.publishableKey));

  return (
    <>
      {stripeInstance ? (
        <Elements
          options={{
            clientSecret: initData.paymentIntent,
            customerOptions: {
              customer: initData.customer,
              ephemeralKey: initData.ephemeralKey,
            },
          }}
          stripe={stripeInstance}
        >
          <StripeCheckoutForm callback={props.callback}/>
        </Elements>
      ) : (
        <></>
      )}
    </>
  );
};
