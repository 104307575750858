import { colors } from "@mui/material";
import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const orders: StyleModel = {
  mobile: {
    wrapper: {
      border: "none"      
    },
    noOrders: {
      color: AppColors.MainColor,
      bgcolor: AppColors.LightColor,
      p: 4,
      borderRadius: 1.8,
      textAlign: "center",
    },
    exploreLink: {
      backgroundColor: AppColors.MainColor,
      borderRadius: "10px",
      padding: "20px 30px",
      display: "block",
      margin: "auto",
      width: "fit-content",
      color: AppColors.White,
      textDecoration: "none",
      fontFamily: "'Ubuntu'",
      marginTop: "20px",
      boxShadow: "#1976d254 -2px 0px 19px 4px"
    },
    pagination: {
      marginLeft: "auto",
      display: "block",
      width: "fit-content",
    },
    card: {
      wrapper: {
        bgcolor: AppColors.White,
        borderRadius: 2,
        mb: 2,
        alignItems: "center",
        p: 2,
        boxShadow: "rgb(225 225 225 / 59%) -2px 1px 20px",
        width: "fit-content"
      },
      paymentMethod: {
        color: AppColors.MainColor,
      },
      tag: {
        borderRadius: 4,
        color: AppColors.White,
        pl: 2,
        pr: 2,
        fontSize: "small",
        textAlign: "center",
        display: "block"
      },
      orderId: {
        color: colors.blue[500],
      },
      productList: {
        ml: 2,
        mr: 2,
      },
      title: {
        color: AppColors.Blue
      },
      datetime: {
        color: colors.grey[500],
        fontSize: "small"
      },
      product: {
        price: {},
        trackOrder: {
          color: AppColors.MainColor,
        },
        quantity: {},
      },
      viewMore: {
        display: "block",
        marginLeft: "auto",
      },
    },
    details: {
      orderNo: {
        marginLeft: 5,
      },
      price: {
        fontWeight: "medium",
        textAlign: "right",
      },
      total: {
        fontSize: "large",
        fontWeight: "bold",
        textAlign: "right",
        color: AppColors.Blue,
      },
    },
  },
  desktop: {
    card: {
      wrapper: {
        width: "100%"
      }
    }
  },
};
