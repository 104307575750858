import { Delete, DrawRounded } from "@mui/icons-material";
import { Avatar, Button, Dialog, Icon, Typography } from "@mui/material";
import React, { useState } from "react";
import { AppColors } from "../../../common/AppColors";
import { apiBaseURL } from "../../../common/Helper";
import { SignatureModel } from "../../../models/SignatureModel";
import { AddSignatureForm } from "./AddSignatureForm";
import { useDeleteFileMutation } from "../../../services/FileServices";

interface Props {
  index: number;
  label: string;
  signatureModel?: SignatureModel;
  setSignatureModel(value?: SignatureModel): void;
  removeFromStore(signatureIndex: number): void;
}
const AddSignatureButton: React.FC<Props> = (props) => {
  const [showAddSignatureForm, setShowAddSignatureForm] = useState(false);
  const [deleteSignatureFile] = useDeleteFileMutation();

  function toggleAddSignatureForm() {
    setShowAddSignatureForm(!showAddSignatureForm);
  }

  async function handleDeleteSignature(event: any) {
    try {
      event?.stopPropagation();
      await deleteSignatureFile(props.signatureModel?.fileModel?.path!);
      props.setSignatureModel(undefined);
      props.removeFromStore(props.index);
    } catch {}
  }

  function handleSubmitAddSignature(signatureModel: SignatureModel) {
    toggleAddSignatureForm();
    props.setSignatureModel(signatureModel);
  }

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={toggleAddSignatureForm}
        sx={{
          p: 3,
          bgcolor: AppColors.LightColor,
          color: AppColors.Black,
          "&: hover": {
            bgcolor: AppColors.LightGray,
          },
          display: "block",
          borderRadius: 1.5,
        }}
      >
        {props.signatureModel ? (
          <>
            <Icon
              onClick={handleDeleteSignature}
              sx={{ position: "absolute", top: "0", right: "0" }}
            >
              <Delete color="error" fontSize="small" />
            </Icon>
            <Avatar
              variant="square"
              sx={{ width: "100%", height: "100%" }}
              src={apiBaseURL + props.signatureModel.fileModel?.path}
            ></Avatar>
          </>
        ) : (
          <>
            <Typography>{props.label}</Typography>
            <Icon>
              <DrawRounded />
            </Icon>
          </>
        )}
      </Button>
      <Dialog open={showAddSignatureForm} onClose={toggleAddSignatureForm}>
        <AddSignatureForm
          submitAddSignatureForm={handleSubmitAddSignature}
          signatureModel={props.signatureModel!}
        />
      </Dialog>
    </>
  );
};

export default AddSignatureButton;
