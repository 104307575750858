import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authAPI } from "../services/AuthServices";
import SelectedCountryReducer from "./Slices/SelectedCountrySlice";
import ResellerFormReducer from "./Slices/ResellerFormSlice";
import CompanyDataReducer from "./Slices/CompanyDataSlice";
import DirectorsReducer from "./Slices/DirectorsSlice";
import SuppliersReducer from "./Slices/SupplierSlice";
import ProfileReducer from "./Slices/ProfileSlice";
import { productAPI } from "../services/ProductServices";
import { filesAPI } from "../services/FileServices";
import AppReducer from "./Slices/AppSlice";
import { rtkQueryErrorLogger } from "../services/ErrorLogger";
import { dashboardAPI } from "../services/DashboardServices";
import { profileAPI } from "../services/ProfileServices";
import { appAPI } from "../services/AppServices";
import CartReducer from "./Slices/CartSlice";
import { ordersAPI } from "../services/MyOrderServices";
import { checkoutAPI } from "../services/CheckoutServices";
import { trackingAPI } from "../services/ProductTrackingServices";
import { cartAPI } from "../services/CartServices";
import { wishlistAPI } from "../services/WishlistServices";
import { categoryAPI } from "../services/CategoriesServices";

const reducers = combineReducers({
    // API Reducers -------------------------------------
    [appAPI.reducerPath]: appAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [productAPI.reducerPath]: productAPI.reducer,
    [filesAPI.reducerPath]: filesAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [ordersAPI.reducerPath]: ordersAPI.reducer,
    [checkoutAPI.reducerPath]: checkoutAPI.reducer,
    [trackingAPI.reducerPath]: trackingAPI.reducer,
    [cartAPI.reducerPath]: cartAPI.reducer,
    [wishlistAPI.reducerPath]: wishlistAPI.reducer,
    [categoryAPI.reducerPath]: categoryAPI.reducer,

    // Other---------------------------------------------
    app: AppReducer,
    selectedCountry: SelectedCountryReducer,
    resellerFormData: ResellerFormReducer,
    companyData: CompanyDataReducer,
    directors: DirectorsReducer,
    suppliers: SuppliersReducer,
    profile: ProfileReducer,
    cart: CartReducer
});

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            rtkQueryErrorLogger,
            appAPI.middleware,
            authAPI.middleware,
            productAPI.middleware,
            filesAPI.middleware,
            dashboardAPI.middleware,
            profileAPI.middleware,
            ordersAPI.middleware,
            checkoutAPI.middleware,
            trackingAPI.middleware,
            cartAPI.middleware,
            wishlistAPI.middleware,
            categoryAPI.middleware
        ),
});

setupListeners(store.dispatch);

export default store;
