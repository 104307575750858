import React from "react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../../models/ResponseModel";
import { showToastMessage } from "../../common/Helper";
import { useSubscribeForStockBackMutation } from "../../services/ProductServices";
import { NotificationAdd } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import { AppColors } from "../../common/AppColors";
import useInputDialog from "../../hooks/useInputDialog";
import { StockBackQuantityField } from "./StockBackQuantityField";

interface Props {
  productId: number;
}

export const BackToStockNotification: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [subscribeForStockBackNotiication] = useSubscribeForStockBackMutation();
  const [QuantityDialog, enterQuantity] = useInputDialog(
    StockBackQuantityField
  );

  async function handleClickNotifyMe() {
    let quantity = await enterQuantity();
    if (quantity) {
      dispatch(setIsLoading(true));
      subscribeForStockBackNotiication({
        productId: props.productId,
        quantity: quantity,
      })
        .then((res: any) => {
          let response = res.data as ResponseModel;
          dispatch(setIsLoading(false));
          showToastMessage(response.status, response.message);
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }

  return (
    <>
      <Typography
        color={AppColors.MainColor}
        mt={1.5}
        variant="h5"
        textAlign={"center"}
      >
        Out of Stock
      </Typography>
      <Button
        endIcon={<NotificationAdd />}
        fullWidth
        onClick={handleClickNotifyMe}
      >
        Notify me when available
      </Button>
      <QuantityDialog />
    </>
  );
};
