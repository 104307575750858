import React, { useEffect } from "react";
import { useParams } from "react-router";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Grid, Typography } from "@mui/material";
import { Wallpaper } from "../components/shared/Wallpaper";
import { AppColors } from "../common/AppColors";
import { PrivacyPolicy } from "../components/Policy/PrivacyPolicy";
import { Container } from "@mui/system";
import { companyEmail, scrollToTop } from "../common/Helper";

const privacyPolicy = "privacy";

export const PolicyPage: React.FC = () => {
  const { type } = useParams();

  useEffect(() => {
    scrollToTop();
  }, [type]);

  return (
    <>
      <Header />
      <Grid container spacing={2} justifyContent={"center"}>
        <Wallpaper
          content={
            <Typography
              variant="h4"
              color={AppColors.White}
              position={"absolute"}
              bottom={"10px"}
              ml={{ xs: "4.83vw", lg: "7.29vw" }}
              textAlign={"center"}
            >
              {type === privacyPolicy
                ? "Privacy Policy"
                : "Return & Refund Policy"}
            </Typography>
          }
        />
      </Grid>
      <Container maxWidth={"lg"}>
        <Grid container mt={4} mb={4} ml={2} mr={2}>
          <Grid xs={12} item>
            {type === privacyPolicy ? (
              <PrivacyPolicy />
            ) : (
              <>
                <Typography variant="body1">
                  In instances where your purchase doesn't satisfy you, you can
                  get in touch with our sales team one (1) day after receiving
                  the items physically. You can send your return and refund
                  request to us at{" "}
                  <a href={"mailto:" + companyEmail}>{companyEmail}</a> within
                  the allowed time so we can resolve the problem.
                  <br />
                  <br />
                  This refund policy does not apply to goods which have been
                  used, damaged, or if any attempt has been made to alter the
                  product, or if they have been dropped or broken after
                  delivery. All products must be returned in their original
                  condition. Please note too that this refund policy does not
                  apply to any digital, electronic, or ESD products. Digital
                  keys/licenses are considered USED once delivered via email.
                  This follows the fact that we have, in any way, no means to
                  verify whether the key has already been activated/redeemed by
                  the customer or not.
                  <br />
                  <br />
                  All postage and insurance costs are to be paid by the buyer.
                  We recommend you return the product via Registered post and
                  you pre-pay all postage. You assume any risk of lost, theft or
                  damaged goods during transit and therefore advise you to avail
                  and register your shipment for insurance with your postal
                  carrier. Softvire AU will not be responsible for parcels lost
                  or damaged in transit if you choose not to insure.
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
