import * as yup from "yup";

export const ResellerFormSchema = yup.object().shape({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  phone: yup.string().required().label("Phone"),
  email: yup.string().email().required().label("Email Address"),
  password: yup.string().min(8).max(20).required().label("Password"),
  confirmPassword: yup
  .string()
  .min(8)
  .max(20)
  .required()
  .label("Confirm Password")
  .oneOf([yup.ref("password")], "Password doesn't match")
});
