import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { ResponseModel } from "../models/ResponseModel";

export const trackingAPI = createApi({
  reducerPath: "trackingAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL,
  }),
  endpoints: (builder) => ({
    getTrackingData: builder.query<ResponseModel, any>({
      query: (trackingId) => `/orders/track/${trackingId}`
    }),
  }),
});

export const { useGetTrackingDataQuery } = trackingAPI;
