import { Star } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Icon,
  Typography,
} from "@mui/material";
import React from "react";
import { ProductModel } from "../../models/ProductModel";
import { useNavigate } from "react-router-dom";
import { AppColors } from "../../common/AppColors";
import { Images } from "../../common/Images";

interface Props {
  product: ProductModel;
  currency: string;
}

const ProductCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const product = props.product;

  function goToProductPage() {
    navigate("/product/" + product.id, { state: props.product });
  }

  return (
    <Card
      variant="outlined"
      sx={{
        display: "inline-block",
        border: "none",
        borderRadius: 2,
        m: 1,
        width: { xs: "45%", lg: "23%" },
        "&: hover": {
          boxShadow: "5px 5px 20px #00000029",
        },
        cursor: "pointer",
        bgcolor: AppColors.LightColor,
      }}
    >
      <div onClick={goToProductPage} style={{ position: "relative" }}>
        <Typography
          variant="subtitle1"
          ml={"auto"}
          display={"flex"}
          alignItems={"center"}
          sx={{
            position: "absolute",
            bgcolor: AppColors.LightColor,
            right: 0,
            pl: 2,
            borderBottomLeftRadius: "8px",
            pr: 1,
          }}
        >
          {(Number(product.averageRating) * 100) / 100}
          <span>
            <Icon>
              <Star color="warning" />
            </Icon>
          </span>
        </Typography>
        <CardMedia
          component="img"
          width={"100%"}
          height={"100%"}
          sx={{ objectFit: "cover", margin: "0" }}
          image={
            product.images && product.images.length > 0
              ? product.images[0].src
              : Images.placeholder
          }
        />
        <CardContent>
          <Typography
            variant="subtitle1"
            height={50}
            lineHeight={1.4}
            overflow={"hidden"}
          >
            {product.name}
          </Typography>
          <Typography color={AppColors.MainColor} fontWeight={"bold"}>
            {product.type === "variable" && "From "}
            {product.price.toFixed(2) + " " + props.currency}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={goToProductPage}
            sx={{
              opacity: !product.isInStock ? 0.5 : 1,
              pt: 1.5,
              pb: 1.5,
              borderRadius: 1.5,
              fontWeight: "bold",
              mt: 1,
            }}
          >
            {!product.isInStock ? "Out of stock" : "Buy Now"}
          </Button>
        </CardContent>
      </div>
    </Card>
  );
};

export default ProductCard;
