import { createSlice } from "@reduxjs/toolkit";
import { CountryModel } from "../../models/CountryModel";
import { localStorageKeys } from "../../common/Helper";

function checkIsCountrySelected() {
    let selectedCountryString = localStorage.getItem(localStorageKeys.country);
    if (selectedCountryString) {
        let tempSelectedCountry: CountryModel = JSON.parse(selectedCountryString);
        return tempSelectedCountry;
    }
}

const selectedCountry = createSlice({
    name: "selectedCountry",
    initialState: {
        value: checkIsCountrySelected(),
    },
    reducers: {
        setSelectedCountry: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setSelectedCountry } = selectedCountry.actions;
export const getSelectedCountry = (state: any) =>
    state.selectedCountry.value as CountryModel;
export default selectedCountry.reducer;
