import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import CategoryList from "../components/Explore/CategoryList";
import { useGetAllProductsQuery } from "../services/ProductServices";
import ProductSlider from "../components/shared/ProductSlider";
import { ProductModel } from "../models/ProductModel";
import { appTitle, snakeToCamel } from "../common/Helper";
import { Helmet } from "react-helmet-async";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { SortByPanel } from "../components/Explore/SortByPanel";
import { sortMethods } from "../models/SortProductsModel";
import { PriceRangeModel } from "../models/PriceRangeModel";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { AppColors } from "../common/AppColors";

export const ExplorePage: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [selectedSortMethod, setSelectedSortMethod] = useState(
    params.get("sort") ?? sortMethods[0].value
  );
  const [featured, setFeatured] = useState(
    params.get("featured") === "1" ? 1 : 0
  );
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [page, setPage] = useState<number>(
    (params.get("page") as unknown as number) ?? 1
  );
  const [priceRange, setPriceRange] = useState<PriceRangeModel>({
    minPrice: params.get("min") ? parseInt(params.get("min")!) : 0,
    maxPrice: params.get("max") ? parseInt(params.get("max")!) : 10000,
  });
  const productsLimit = 24;

  const {
    data: productsResponse,
    error,
    isFetching,
  } = useGetAllProductsQuery({
    limit: productsLimit,
    page: page < 1 ? 1 : page,
    featured: featured,
    sort: selectedSortMethod,
    priceRange: priceRange,
  });

  useEffect(() => {
    if (productsResponse && productsResponse.status === "success") {
      let tempProducts = snakeToCamel(
        productsResponse.response
      ) as ProductModel[];
      setProducts(tempProducts);
    }
  }, [productsResponse]);

  useEffect(() => {
    let params = {
      page: page.toString(),
      featured: featured.toString(),
      sort: selectedSortMethod,
      min: priceRange.minPrice.toString(),
      max: priceRange.maxPrice.toString(),
    };
    navigate(`.?${createSearchParams(params).toString()}`, { replace: true });
  }, [page, featured, selectedSortMethod, priceRange]);

  function handleChangePage(goNext = false) {
    let tempPage = page;
    if (goNext) {
      tempPage++;
    } else if (tempPage > 0) {
      tempPage--;
    }
    setPage(tempPage);
  }

  return (
    <>
      <Helmet>
        <title>{appTitle + " - Explore"}</title>
      </Helmet>
      <Header />
      <Container maxWidth={"xl"}>
        <Grid container sx={{ mt: 4, mb: 4 }} justifyContent={"center"}>
          <Grid item xs={12} md={3} lg={2.5}>
            <CategoryList />
          </Grid>
          <Grid
            item
            md={7}
            lg={9}
            xs={12}
            ml={{ xs: 0, md: 2, lg: 4 }}
            mt={{ xs: 2, lg: 0 }}
          >
            <SortByPanel
              featured={featured}
              setFeatured={setFeatured}
              selectedSortMethod={selectedSortMethod}
              setSelectedSortMethod={setSelectedSortMethod}
              priceRange={priceRange}
              setPriceRange={setPriceRange}
            />
            <ProductSlider
              rows={2}
              slidesPerRow={4}
              title={
                featured
                  ? "On Sale Products"
                  : "Sort by " +
                    sortMethods.find((x) => x.value === selectedSortMethod)
                      ?.name
              }
              productList={products}
              isFetching={isFetching}
              error={error!}
              enableNoProductsMessage
            />
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                display: "block",
                marginLeft: "auto",
                mt: 2,
                width: "fit-content",
              }}
            >
              <IconButton
                sx={{
                  bgcolor: AppColors.LightGray,
                  border: "1px solid " + AppColors.LightGray,
                }}
                onClick={() => handleChangePage()}
              >
                <ArrowLeftOutlined />
              </IconButton>

              <Typography variant="button" fontWeight={"bold"}>
                Page {page < 1 ? 1 : page}
              </Typography>
              <IconButton
                sx={{
                  bgcolor: AppColors.LightGray,
                  border: "1px solid " + AppColors.LightGray,
                }}
                onClick={() => handleChangePage(true)}
              >
                <ArrowRightOutlined />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
