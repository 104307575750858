import { Skeleton } from "@mui/material";
import React from "react";

const loaderElements: JSX.Element[] = [];
for (let i = 0; i < 10; i++) {
  let element = (
    <Skeleton
      key={i}
      height={30}
      sx={{ mt: 1, borderRadius: 1 }}
      variant="rectangular"
    />
  );
  loaderElements.push(element);
}

export const CategoriesLoader: React.FC = () => {
  return <>{loaderElements}</>;
};
