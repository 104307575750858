import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetCategoryProductsQuery } from "../services/ProductServices";
import { appTitle, snakeToCamel } from "../common/Helper";
import { ProductModel } from "../models/ProductModel";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Helmet } from "react-helmet-async";
import { Wallpaper } from "../components/shared/Wallpaper";
import { StyleContext } from "../context/StyleContextProvider";
import {
  Container,
  Grid,
  Icon,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CategoryOutlined,
} from "@mui/icons-material";
import ProductSlider from "../components/shared/ProductSlider";
import { AppColors } from "../common/AppColors";

export const CategoryProductsPage: React.FC = () => {
  const { categoryId } = useParams();
  const location = useLocation();
  const category = location.state ?? "Category Products";
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("categoryProductsPage")
  );

  const isMobile = useMediaQuery("(max-width: 786px)");
  const [page, setPage] = useState<number>(1);
  const resultLimit = 24;
  const {
    data: response,
    isFetching,
    error,
  } = useGetCategoryProductsQuery(
    {
      categoryId: categoryId!,
      limit: resultLimit,
      page: page,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("categoryProductsPage"));
  }, [isMobile]);

  useEffect(() => {
    if (response && response.status === "success") {
      let tempProducts = snakeToCamel(response.response) as ProductModel[];
      setProducts(tempProducts);
    }
  }, [response]);

  function handleChangePage(goNext = false) {
    let tempPage = page;
    if (goNext) {
      tempPage++;
    } else if (tempPage > 0) {
      tempPage--;
    }
    setPage(tempPage);
  }

  return (
    <>
      <Helmet>
        <title>{appTitle + " - " + category}</title>
      </Helmet>
      <Header />
      <Wallpaper
        content={
          <Typography variant="h5" sx={styles.title}>
            {category}
            <Icon sx={styles.favIcon}>
              <CategoryOutlined />
            </Icon>
          </Typography>
        }
      />
      <Container maxWidth={"xl"}>
        <Grid container sx={{ mt: 4, mb: 4 }} justifyContent={"center"}>
          <Grid item xs={12} lg={10}>
            <ProductSlider
              title=""
              rows={0}
              slidesPerRow={0}
              isFetching={isFetching}
              error={error}
              productList={products}
              enableNoProductsMessage
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                display: "block",
                marginLeft: "auto",
                mt: 2,
                width: "fit-content",
              }}
            >
              <IconButton
                sx={{
                  bgcolor: AppColors.LightGray,
                  border: "1px solid " + AppColors.LightGray,
                }}
                onClick={() => handleChangePage()}
                disabled={page === 1}
              >
                <ArrowLeftOutlined />
              </IconButton>

              <Typography variant="button" fontWeight={"bold"}>
                Page {page < 1 ? 1 : page}
              </Typography>
              <IconButton
                sx={{
                  bgcolor: AppColors.LightGray,
                  border: "1px solid " + AppColors.LightGray,
                }}
                onClick={() => handleChangePage(true)}
                disabled={products.length + 1 < resultLimit}
              >
                <ArrowRightOutlined />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
