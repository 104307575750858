import React from "react";
import { SelectOptionModel } from "../../models/SelectOptionModel";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DeliveryAddressSelectModel } from "../../models/DeliveryAddressSelectModel";

interface Props {
  setSelectedDeliveryAddress(address: DeliveryAddressSelectModel): void;
  selectedAddressId?: number;
  addressModelList: DeliveryAddressSelectModel[];
  addressOptions: SelectOptionModel[];
}

export const DeliveryAddressSelect: React.FC<Props> = (props) => {
  function handleSelect(event: SelectChangeEvent) {
    let states = [...props.addressModelList];
    let tempSelectedAddress = states.find(
      (x) => x.id === parseInt(event.target.value)
    );
    if (tempSelectedAddress) {
      props.setSelectedDeliveryAddress(tempSelectedAddress);
    }
  }

  if (props.addressOptions.length === 0) {
    return <></>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Choose address</InputLabel>
      <Select
        fullWidth
        sx={{ p: 0, borderRadius: "10px", height: "45px" }}
        label={"Choose address"}
        onChange={handleSelect}
        value={
          props.addressOptions.length > 0 && props.selectedAddressId
            ? props.selectedAddressId?.toString()
            : ""
        }
      >
        {props.addressOptions.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
