import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import React from "react";
import { ControlledTextField } from "../../shared/ControlledTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SupplierModel } from "../../../models/SupplierModel";
import { SupplierFormSchema } from "../../../schemas/SupplierFormSchema";

interface Props {
  supplier?: SupplierModel;
  handleAddSupplier(supplier: SupplierModel): void;
}

export const SupplierFormPopup: React.FC<Props> = (props) => {
  let supplier = props.supplier;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SupplierModel>({
    resolver: yupResolver(SupplierFormSchema),
    defaultValues: supplier
      ? supplier
      : {
          companyName: "",
          averageSpend: 0,
          contactName: "",
          phone: "",
          email: "",
        },
  });

  async function submit(directorModel: SupplierModel) {
    props.handleAddSupplier(directorModel);
  }

  return (
    <>
      <DialogTitle variant="h4">Add Supplier</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          component={"form"}
          onSubmit={handleSubmit(submit)}
        >
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="companyName"
              label={"Company Name*"}
              error={errors.companyName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="averageSpend"
              label={"Average Monthly Spend*"}
              error={errors.averageSpend}
              inputProps={{
                type: "number",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="contactName"
              label={"Contact Name*"}
              error={errors.contactName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="phone"
              label={"Phone*"}
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="email"
              label={"Email Address*"}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button
              sx={{ p: 2, fontWeight: "bold", borderRadius: 2 }}
              type="submit"
              fullWidth
              variant="contained"
            >
              {supplier ? "Save" : "Add"} Supplier
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
