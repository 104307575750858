import { createSlice } from "@reduxjs/toolkit";
import { localStorageKeys } from "../../common/Helper";
import { CompanyModel } from "../../models/CompanyModel";

function checkCompanyFormFilled() {
    let companyDataString = localStorage.getItem(localStorageKeys.company);
    if (companyDataString) {
        let companyInitialData: CompanyModel = JSON.parse(companyDataString);
        return companyInitialData;
    }
}

const companyData = createSlice({
    name: "companyData",
    initialState: {
        value: checkCompanyFormFilled(),
    },
    reducers: {
        setcompanyData: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setcompanyData } = companyData.actions;
export const getcompanyData = (state: any) =>
    state.companyData.value as CompanyModel;
export default companyData.reducer;
