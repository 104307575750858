import {
  Avatar,
  Button,
  DialogContent,
  FormHelperText,
  Grid,
} from "@mui/material";
import React, { useRef } from "react";
import { ControlledTextField } from "../../shared/ControlledTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignatureModel } from "../../../models/SignatureModel";
import { AddSignatureFormSchema } from "../../../schemas/AddSignatureFormSchema";
import { SelectOptionModel } from "../../../models/SelectOptionModel";
import { AppColors } from "../../../common/AppColors";
import { apiBaseURL, showToastMessage } from "../../../common/Helper";
import SignatureCanvas from "react-signature-canvas";
import { usePostFormDataMutation } from "../../../services/FileServices";
import { ResponseModel } from "../../../models/ResponseModel";
import { FileModel } from "../../../models/FileModel";
import { setIsLoading } from "../../../reduxenv/Slices/AppSlice";
import { useDispatch } from "react-redux";

interface Props {
  signatureModel: SignatureModel;
  submitAddSignatureForm(value: SignatureModel): void;
}

const roleOptions: SelectOptionModel[] = [
  { option: "Director", value: "Director" },
  { option: "Partner", value: "Partner" },
  { option: "Principal", value: "Principal" },
];

export const AddSignatureForm: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignatureModel>({
    resolver: yupResolver(AddSignatureFormSchema),
    defaultValues: props.signatureModel
      ? props.signatureModel
      : {
          name: "",
          role: "",
        },
  });
  const signatureCanvas = useRef<SignatureCanvas | null>(null);
  const [uploadSignatureFile] = usePostFormDataMutation();
  const dispatch = useDispatch();

  async function submit(signatureModel: SignatureModel) {
    try {
      let signCanvas = signatureCanvas.current;
      if (!signCanvas?.isEmpty()) {
        dispatch(setIsLoading(true));
        signCanvas?.getCanvas().toBlob((file) => {
          let signatureFile = new File([file!], "signature.png", {
            type: "image/png",
          });
          let formData = new FormData();
          formData.append("file", signatureFile);
          uploadSignatureFile({ formData: formData, directory: "" }).then(
            (data: any) => {
              let responseModel = data.data as ResponseModel;
              if (responseModel.status === "success") {
                let tempFileModel = responseModel.response as FileModel;
                signatureModel.fileModel = tempFileModel;
                props.submitAddSignatureForm(signatureModel);
              }
              dispatch(setIsLoading(false));
            }
          );
        }, "image/png");
      } else {
        showToastMessage("error", "Signature is required!");
      }
    } catch {
      showToastMessage("error", "There was an error during the process!");
      dispatch(setIsLoading(true));
    }
  }

  return (
    <DialogContent>
      <Grid
        container
        spacing={2}
        component={"form"}
        onSubmit={handleSubmit(submit)}
      >
        <Grid item xs={12}>
          <ControlledTextField
            label="Your Name"
            control={control}
            name="name"
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Select Your Position"
            control={control}
            name="role"
            error={errors.role}
            select
            selectOptions={roleOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText sx={{ textAlign: "center", color: AppColors.MainColor }}>
            Draw your signature below
          </FormHelperText>
          <div
            style={{
              border: "1px solid " + AppColors.MainColor,
              borderRadius: 10,
              width: "100%",
            }}
          >
            {props.signatureModel ? (
              <Avatar
                variant="square"
                sx={{ width: "100%", height: "100%" }}
                src={apiBaseURL + props.signatureModel.fileModel?.path}
              />
            ) : (
              <SignatureCanvas
                canvasProps={{ className: "signature-canvas" }}
                penColor="blue"
                ref={signatureCanvas}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" fullWidth>
            Add Signature
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
