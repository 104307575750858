import { colors } from "@mui/material";
import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const productPage: StyleModel = {
  mobile: {
    productName: {
      fontSize: "4.83vw",
      color: AppColors.White,
    },
    wallpaperContent: {
      color: AppColors.White,
      position: "absolute",
      bottom: "1.41vw",
      ml: "4.83vw",
    },
    actionWrapper: {
      boxShadow: "5px 5px 20px #00000029",
      m: 1,
    },
    noRating: {
      color: AppColors.DarkGray
    },
    rrpWrapper: {
      textAlign: "right",
      mt: 2
    },
    rrp: {
      color: colors.green[500],
      marginLeft: "10px",
      fontWeight: "unset",
      fontSize: "small",
    },
    priceWrapper: {
      bgcolor: AppColors.LightColor,
      display: "flex",
      color: AppColors.Blue,
      pl: 2,
      pr: 2,
      pt: 1,
      pb: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    price: {
      fontWeight: "bold",
      fontSize: "5vw",
      textAlign: "center"
    },
    gst: {
      fontSize: "2.41vw",
      marginTop: "0",
      marginBottom: "0",
    },
    mainButton: {
      p: 1.5,
      borderRadius: 1.8,
      boxShadow: "none",
      fontSize: "large",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "5px 5px 20px #00000029",
      },
    },
    variationsBox: {
      borderRadius: 1.8,
      border: "1px solid " + AppColors.MainColor,
      pl: 1,
      pb: 1,
      height: "100%",
    },
    variant: {
      borderRadius: 1.8,
      mt: 1,
      mr: 1,
      display: "inline-block",
    },
    wishlistButton: {
      color: AppColors.White,
      bgcolor: AppColors.MainColor,
      borderRadius: 4,
      pt: 0,
      pb: 0,
      "&: hover": {
        bgcolor: AppColors.MainColor,
      },
    },
    wishlistButtonMobile: {
      color: AppColors.White,
      bgcolor: AppColors.MainColor,
      "&: hover": {
        bgcolor: AppColors.MainColor,
      },
    },
    rightWrapper: {
      mt: 1
    }
  },
  desktop: {
    productName: {
      fontSize: "2vw",
      width: "48.5vw",
    },
    price: {
      fontSize: "2.41vw",
      borderRadius: "0.52vw",
    },
    gst: {
      fontSize: "1.4vw",
      marginBottom: "0.4vw",
    },
    leftWrapper: {
      ml: 4,
      mt: 2,
    },
    wallpaperContent: {
      ml: "7.29vw",
      bottom: "0.83vw",
    },
    selectionWrapper: {
      mt: 5,
    },
    rightWrapper: {
      mt: -15,
      ml: 4,
      position: "relative",
    },
  },
};
