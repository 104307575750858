import { createSlice } from "@reduxjs/toolkit";
import { localStorageKeys } from "../../common/Helper";
import { ProfileModel } from "../../models/ProfileModel";
import { LocalStorage } from "../../common/Localstorage";

function checkLoginStatus() {
    let profileString = localStorage.getItem(localStorageKeys.profile);
    if (profileString) {
        let tempProfile: ProfileModel = JSON.parse(profileString);
        return tempProfile;
    }
}

const profile = createSlice({
    name: "profile",
    initialState: {
        profileModel: checkLoginStatus(),
        token: LocalStorage.getToken()
    },
    reducers: {
        setProfile: (state, action) => {
            state.profileModel = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        }
    },
});

export const { setProfile, setToken } = profile.actions;
export const getProfile = (state: any) =>
    state.profile.profileModel as ProfileModel;
export const getToken = (state: any) =>
    state.profile.token as string;
export default profile.reducer;
