import { useSelector } from "react-redux";
import { getProfile } from "../reduxenv/Slices/ProfileSlice";

export const useFormatCurrency = () => {
  const profile = useSelector(getProfile);

  const formatCurrency = (amount: number | undefined) => {
    let formatedAmount = "0";
    if (amount) {
      formatedAmount =
        (amount / 1000 >= 10
          ? (amount / 1000).toFixed(1) + "K"
          : amount.toFixed(2)) +
        " " +
        profile.country.currency;
    }
    return formatedAmount;
  };
  return { formatCurrency };
};
