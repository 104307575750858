import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const resetPassword: StyleModel = {
  mobile: {
    title: {
      position: "absolute",
      bottom: "10px",
      marginLeft: "4.83vw",
      color: AppColors.White,
    },
    form: {
      m: 3,
      p: 2,
      bgcolor: AppColors.LightColor,
      boxShadow: "rgb(225 225 225 / 59%) -2px 1px 20px",
      position: "relative",
      zIndex: "1"
    },
    image: {
        width: "100%",
        height: "fit-content"
    }
  },
  desktop: {
    title: {
      marginLeft: "7.29vw",
    },
    imageWrapper: {
        mt: -10,
        ml: -8
    }
  },
};
