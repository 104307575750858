import React, { useEffect, useState } from "react";
import { appTitle, snakeToCamel } from "../common/Helper";
import { ProductModel } from "../models/ProductModel";
import { useSearchProductsQuery } from "../services/ProductServices";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import ProductSlider from "../components/shared/ProductSlider";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { AppColors } from "../common/AppColors";

export const SearchResultsPage: React.FC = () => {
  const { keyword } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>(keyword!);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [page, setPage] = useState<number>(1);
  const resultLimit = 24;
  const {
    data: searchResponse,
    isFetching,
    error,
  } = useSearchProductsQuery(
    {
      keyword: searchQuery!,
      limit: resultLimit,
      page: page,
    },
    { skip: !searchQuery, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setSearchQuery(keyword!);
  }, [keyword]);

  useEffect(() => {
    if (searchQuery && searchResponse?.status === "success") {
      let searchResults = snakeToCamel(
        searchResponse.response
      ) as ProductModel[];
      setProducts(searchResults);
    }
  }, [searchResponse]);

  function handleChangePage(goNext = false) {
    let tempPage = page;
    if (goNext) {
      tempPage++;
    } else if (tempPage > 0) {
      tempPage--;
    }
    setPage(tempPage);
  }

  return (
    <>
      <Helmet>
        <title>{appTitle + " - Explore"}</title>
      </Helmet>
      <Header searchQuery={searchQuery} />
      <Container maxWidth={"lg"}>
        <Grid container sx={{ mt: 4, mb: 4 }} margin={"auto"}>
          {/* <CategoryList /> */}
          <Grid item lg={12} xs={12}>
            <ProductSlider
              rows={2}
              slidesPerRow={4}
              title={"Search Results for '" + searchQuery + "'"}
              productList={products!}
              isFetching={isFetching}
              error={error!}
              enableNoProductsMessage
            />
          </Grid>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: "block",
              marginLeft: "auto",
              mt: 2,
              width: "fit-content",
            }}
          >
            <IconButton
              sx={{
                bgcolor: AppColors.LightGray,
                border: "1px solid " + AppColors.LightGray,
              }}
              onClick={() => handleChangePage()}
              disabled={page === 1}
            >
              <ArrowLeftOutlined />
            </IconButton>

            <Typography variant="button" fontWeight={"bold"}>
              Page {page < 1 ? 1 : page}
            </Typography>
            <IconButton
              sx={{
                bgcolor: AppColors.LightGray,
                border: "1px solid " + AppColors.LightGray,
              }}
              onClick={() => handleChangePage(true)}
              disabled={products.length + 1 < resultLimit}
            >
              <ArrowRightOutlined />
            </IconButton>
          </Stack>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
