import { Box, Paper, Typography, colors } from "@mui/material";
import React from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Line,
} from "recharts";
import { AppColors } from "../../common/AppColors";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <Box
        bgcolor={AppColors.LightColor}
        p={2}
        borderRadius={1.5}
        sx={{ opacity: 0.9 }}
      >
        <Typography variant="button">{label}</Typography>
        {payload &&
          payload.map((entry: any, index: any) => (
            <Typography variant="subtitle2" key={`value-${index}`}>
              {`${entry.name}: ${entry.value.toFixed(2)}`}{" "}
            </Typography>
          ))}
      </Box>
    );
  }

  return null;
};

const SalesGraph: React.FC<any> = (props: { graphData: any }) => {
  return (
    <Paper
      sx={{
        pt: 1,
        pb: 1,
        pr: 1,
        height: "50vh",
        border: "2px solid " + AppColors.Blue,
        borderRadius: 1.5,
      }}
    >
      <Typography textAlign={"center"} variant="h5">
        Past Orders Summary
      </Typography>
      <ResponsiveContainer width="100%" height="92%">
        <LineChart width={500} height={300} data={props.graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            name="Total"
            dataKey="total"
            stroke={colors.blue[800]}
            type={"monotone"}
          />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default SalesGraph;
