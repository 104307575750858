import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";
import { PriceRangeModel } from "../models/PriceRangeModel";

export const productAPI = createApi({
  reducerPath: "productAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL + "products/",
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProductById: builder.query<ResponseModel, { productId: number }>({
      query: (params) => {
        return "/id/" + params.productId;
      },
    }),
    getAllProducts: builder.query<
      ResponseModel,
      {
        limit: number;
        page: number;
        featured: number;
        sort: string;
        priceRange: PriceRangeModel;
      }
    >({
      query: (params) => {
        return `all?limit=${params.limit}&page=${params.page}&featured=${params.featured}&sort=${params.sort}&minPrice=${params.priceRange.minPrice}&maxPrice=${params.priceRange.maxPrice}`;
      },
    }),
    getAllVariations: builder.query<ResponseModel, { productId: number }>({
      query: (params) => {
        return "/variations/" + params.productId;
      },
    }),
    searchProducts: builder.query<
      ResponseModel,
      { keyword: string; limit: number; page: number }
    >({
      query: (params) => {
        return `search/${params.keyword}?limit=${params.limit}&page=${params.page}`;
      },
    }),
    subscribeForStockBack: builder.mutation<
      ResponseModel,
      { productId: number; quantity: number }
    >({
      query: (params) => ({
        url: "subscribe",
        method: "POST",
        body: { productId: params.productId, quantity: params.quantity },
      }),
    }),
    getAllSavedPlaces: builder.query<
      ResponseModel,
      { parentId: number; productId: number }
    >({
      query: (params) =>
        "/saved-places/" + params.parentId + "/" + params.productId,
      keepUnusedDataFor: 0.0001,
    }),
    getCategoryProducts: builder.query<
      ResponseModel,
      { categoryId: string; limit: number; page: number }
    >({
      query: (params) =>
        `/category/${params.categoryId}?limit=${params.limit}&page=${params.page}`,
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetProductByIdQuery,
  useGetAllVariationsQuery,
  useSearchProductsQuery,
  useSubscribeForStockBackMutation,
  useGetAllSavedPlacesQuery,
  useGetCategoryProductsQuery,
} = productAPI;
