import * as yup from "yup";

export const EditProfileFormSchema = yup.object().shape({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  phone: yup.string().required().label("Phone"),
  address1: yup.string().required().label("Address Line 1"),
  address2: yup.string().required().label("Address Line 2"),
  state: yup.string().required().label("State"),
});
