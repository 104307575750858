import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  useGetAgreementFilePathQuery,
  usePostSignupDetailsMutation,
} from "../../services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import {
  getcompanyData,
  setcompanyData,
} from "../../reduxenv/Slices/CompanyDataSlice";
import { getResellerFormData } from "../../reduxenv/Slices/ResellerFormSlice";
import { getDirectors } from "../../reduxenv/Slices/DirectorsSlice";
import { getSuppliers } from "../../reduxenv/Slices/SupplierSlice";
import { SignupCompanyModel } from "../../models/SignupCompanyModel";
import {
  apiBaseURL,
  localStorageKeys,
  showToastMessage,
} from "../../common/Helper";
import { getSelectedCountry } from "../../reduxenv/Slices/SelectedCountrySlice";
import { ResponseModel } from "../../models/ResponseModel";
import { SignatureModel } from "../../models/SignatureModel";
import AddSignatureButton from "./termsConditions/AddSignatureButton";
import { LocalStorage } from "../../common/Localstorage";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { AgreementFileParam } from "../../models/AgreementFileParams";

const TermsAndConditionsForm: React.FC = () => {
  const dispatch = useDispatch();
  const [postSignupDetails] = usePostSignupDetailsMutation();
  const [getAgreementFileParams, setGetAgreementFileParams] =
    useState<AgreementFileParam>();
  const { data: agreementFilePath, isFetching: isFetchingAgreement } =
    useGetAgreementFilePathQuery(getAgreementFileParams!, {
      skip: !getAgreementFileParams,
    });
  const reseller = useSelector(getResellerFormData);
  const company = useSelector(getcompanyData);
  const directors = useSelector(getDirectors);
  const suppliers = useSelector(getSuppliers);
  const country = useSelector(getSelectedCountry);
  const [signatureModel1, setSignatureModel1] = useState<
    SignatureModel | undefined
  >(
    checkAlreadySigned()
      ? {
          name: "",
          role: "",
          fileModel: { name: "", path: company.signatures![0].path },
        }
      : undefined
  );
  const [signatureModel2, setSignatureModel2] = useState<
    SignatureModel | undefined
  >(
    checkAlreadySigned(1) // for the 2nd sign.
      ? {
          name: "",
          role: "",
          fileModel: { name: "", path: company.signatures![1].path },
        }
      : undefined
  );
  const [downloadAgreementAfterSubmit, setDownloadAgreementAfterSubmit] =
    useState(false);
  const [skipSignatures, setSkipSignatures] = useState(false);

  // after getting the agreement file open in it in new window or try using PdfTron
  useEffect(() => {
    if (agreementFilePath && agreementFilePath.status === "success") {
      showToastMessage(
        "success",
        "Agreement file is being opened in another tab"
      );
      window.open(apiBaseURL + agreementFilePath.response.path, "_blank");
      LocalStorage.clearStorage();
      window.location.href = "/auth/registration-succeed";
    }
  }, [agreementFilePath]);

  useEffect(() => {
    dispatch(setIsLoading(isFetchingAgreement));
  }, [isFetchingAgreement]);

  function checkAlreadySigned(signCount = 0) {
    return !!company.signatures && company.signatures.length > signCount;
  }

  async function agreeAndSubmit() {
    let tempCompany = { ...company };
    if (signatureModel1 && signatureModel2) {
      tempCompany.signatures = [
        {
          name: `${signatureModel1.name} (Role: ${signatureModel1.role})`,
          path: signatureModel1.fileModel?.path!,
        },
        {
          name: `${signatureModel2.name} (Role: ${signatureModel2.role})`,
          path: signatureModel2.fileModel?.path!,
        },
      ];
      // Storing the signature paths.
      localStorage.setItem(
        localStorageKeys.company,
        JSON.stringify(tempCompany)
      );
    }
    let signupDetails: SignupCompanyModel = {
      reseller: reseller,
      company: tempCompany,
      directors: directors,
      suppliers: suppliers,
    };
    dispatch(setIsLoading(true));
    dispatch(setcompanyData(tempCompany));
    postSignupDetails(signupDetails)
      .then((data: any) => {
        dispatch(setIsLoading(false));
        let response = data.data as ResponseModel;
        if (response.status === "success") {
          // This will trigger the Get AgreementFile call!
          if (downloadAgreementAfterSubmit && !skipSignatures) {
            setGetAgreementFileParams({
              countryId: country.id,
              companyId: response.response.company_id,
            });
          } else {
            LocalStorage.clearStorage();
            window.location.href = "/auth/registration-succeed";
          }
          showToastMessage("success", response.message);
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
      });
  }

  function removeSignatureFromStore(signatureIndex: number) {
    let tempCompany = { ...company };
    let tempSigatures = [...tempCompany.signatures!];
    tempSigatures.splice(signatureIndex, 1);
    tempCompany.signatures = tempSigatures;
    localStorage.setItem(localStorageKeys.company, JSON.stringify(tempCompany));
    dispatch(setcompanyData(tempCompany));
  }

  function handleChangeSkipSignatures(_e: any, checked: boolean) {
    setSignatureModel1(undefined);
    setSignatureModel2(undefined);
    setSkipSignatures(checked);
    if (checked) {
      setDownloadAgreementAfterSubmit(false);
    }
  }

  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <CardContent>
        <div
          dangerouslySetInnerHTML={{
            __html: country.termsAndConditions,
          }}
        ></div>
        <Grid container justifyContent={"space-around"} mt={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Signature*</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={skipSignatures}
                  onChange={handleChangeSkipSignatures}
                />
              }
              label={"Upload signatures later"}
            />
            <Divider />
          </Grid>
          {!skipSignatures && (
            <>
              <Grid item xs={5} lg={4} mt={2}>
                <AddSignatureButton
                  label={"Add Signature 1"}
                  signatureModel={signatureModel1}
                  setSignatureModel={setSignatureModel1}
                  removeFromStore={removeSignatureFromStore}
                  index={0}
                />
              </Grid>
              <Grid item xs={5} lg={4} mt={2}>
                <AddSignatureButton
                  label={"Add Signature 2"}
                  signatureModel={signatureModel2}
                  setSignatureModel={setSignatureModel2}
                  removeFromStore={removeSignatureFromStore}
                  index={1}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} lg={10} mt={2}>
            <Button
              onClick={agreeAndSubmit}
              color="error"
              variant="contained"
              sx={{ borderRadius: 2, p: 2, fontWeight: "bold" }}
              fullWidth
              disabled={
                (!signatureModel1 || !signatureModel2) && !skipSignatures
              }
            >
              Agree & Submit
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={downloadAgreementAfterSubmit}
                  disabled={skipSignatures}
                  onChange={(event) => {
                    setDownloadAgreementAfterSubmit(event.target.checked);
                  }}
                />
              }
              label={"Download Agreement After Submiting"}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TermsAndConditionsForm;
