import { DeleteRounded, Remove } from "@mui/icons-material";
import { Badge, IconButton, Avatar, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import Dropzone from "react-dropzone";
import { apiBaseURL } from "../../common/Helper";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../../models/ResponseModel";
import {
  usePostFormDataMutation,
  useDeleteFileMutation,
} from "../../services/FileServices";
import { FileModel } from "../../models/FileModel";
import { validateFile } from "../../common/FileValidation";

interface Props {
  reciptFile: FileModel;
  setReciptFile(value: FileModel | undefined): void;
  styles: any;
}

export const BankReciptUpload: React.FC<Props> = (props) => {
  let reciptFile = props.reciptFile;
  let styles = props.styles;

  const dispatch = useDispatch();
  const [uploadRecipt] = usePostFormDataMutation();
  const [deleteRecipt] = useDeleteFileMutation();

  async function handleSelectRecipt(files: File[]) {
    try {
      if (files && files.length > 0 && validateFile(files[0].name)) {
        let formdata = new FormData();
        formdata.append("file", files[0]);
        dispatch(setIsLoading(true));
        uploadRecipt({ formData: formdata, directory: "reciepts" })
          .then((response: any) => {
            let responseModel = response.data as ResponseModel;
            if (responseModel.status === "success") {
              props.setReciptFile({
                name: responseModel.response.name,
                path: responseModel.response.path,
              });
            }
            dispatch(setIsLoading(false));
          })
          .catch((e) => {
            dispatch(setIsLoading(false));
          });
      }
    } catch {
      dispatch(setIsLoading(false));
    }
  }

  async function deleteUploadedRecipt(event: React.MouseEvent) {
    try {
      event.stopPropagation();
      dispatch(setIsLoading(true));
      deleteRecipt(reciptFile.path!)
        .then((res: any) => {
          let response = res.data as ResponseModel;
          if (response.status === "success") {
            props.setReciptFile(undefined);
            dispatch(setIsLoading(false));
          }
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    } catch {}
  }

  return (
    <Dropzone onDrop={handleSelectRecipt}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            style: styles.wrapper,
            className: "center-content",
          })}
        >
          <input {...getInputProps()} />
          {reciptFile ? (
            <Badge
            sx={styles.content}
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={
                <IconButton
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                  onClick={deleteUploadedRecipt}
                >
                  <Avatar
                    sx={{
                      bgcolor: red[500],
                    }}
                  >
                    <DeleteRounded />
                  </Avatar>
                </IconButton>
              }
            >
              {reciptFile.path.split(".")[1] === "pdf" ? (
                <iframe style={styles.preview} src={apiBaseURL + reciptFile.path} />
              ) : (
                <Avatar
                  sx={styles.preview}
                  variant="square"
                  src={apiBaseURL + reciptFile.path}
                />
              )}
            </Badge>
          ) : (
            <Typography variant="caption" sx={styles.label}>
              Drop OR Click here to upload the Recipt
            </Typography>
          )}
        </div>
      )}
    </Dropzone>
  );
};
