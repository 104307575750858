export type CreditStatusModel = {
  status: string;
  note: string;
};

export const CreditCustomerStatuses = {
  none: "NONE",
  pending: "PENDING",
  active: "ACTIVE",
  rejected: "REJECTED",
  suspended: "SUSPENDED",
};
