import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";

export const wishlistAPI = createApi({
  reducerPath: "wishlistAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL + "wishlist/",
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllWishlistItems: builder.query<ResponseModel, void>({
      query: () => "all",
      keepUnusedDataFor: 0.0001,
    }),
    addToWishlist: builder.mutation<ResponseModel, {productId: number, parentId: number}>({
      query: (params) => ({
        url: "add",
        method: "POST",
        body: {
          productId: params.productId,
          parentId: params.parentId
        },
      }),
    }),
    deleteFromWishlist: builder.mutation<ResponseModel, {parentId: number, productId: number}>({
      query: (param) => ({
        url: `delete/${param.parentId}/${param.productId}`,
        method: "DELETE",
        body: {},
      }),
    }),
  }),
});

export const { useGetAllWishlistItemsQuery, useDeleteFromWishlistMutation, useAddToWishlistMutation } =
  wishlistAPI;
