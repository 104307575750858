import * as yup from "yup";

export const DirectorFormSchema = yup.object().shape({
    name: yup.string().required().label("Name"),
    dob: yup
        .string()
        .required()
        .label("Date of Birth")
        .typeError("Date of Birth is a required field"),
    houseNumber: yup.string().required().label("This"),
    streetName: yup.string().required().label("Street Name"),
    state: yup.string().required().label("State"),
    phone: yup.string().required().label("Phone"),
    email: yup.string().email().required().label("Email Address"),
    bankrupt: yup.bool().required().label("Bank rupt"),
    pastDirectorships: yup.array().required(),
});
