import {
  Button,
  Card,
  CardContent,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../../shared/ControlledTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestResetPasswordSchema } from "../../../schemas/RequestResetPasswordSchema";
import { RequestResetPasswordModel } from "../../../models/RequestResetPasswordModel";
import { useRequestResetPasswordMutation } from "../../../services/AuthServices";
import { ResponseModel } from "../../../models/ResponseModel";
import { showToastMessage } from "../../../common/Helper";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../reduxenv/Slices/AppSlice";

interface Props {
    handleClose(): void;
}

export const RequestResetPassword: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RequestResetPasswordModel>({
    resolver: yupResolver(RequestResetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });
  const [requestResetPassword] = useRequestResetPasswordMutation();

  async function submit(data: RequestResetPasswordModel) {
    dispatch(setIsLoading(true));
    requestResetPassword(data.email)
      .then((res: any) => {
        let response = res.data as ResponseModel;
        showToastMessage(response.status, response.message);
        props.handleClose();
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        console.log(error);
        props.handleClose();
        dispatch(setIsLoading(false));
      });
  }

  return (
    <DialogContent>
      <Typography variant="h5" mb={2}>
        Request Password Reset
      </Typography>
      <Grid
        container
        spacing={2}
        component={"form"}
        onSubmit={handleSubmit(submit)}
        justifyContent={"center"}
      >
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            label="Your Email Address"
            name="email"
            inputProps={{ focused: true }}
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button type="submit" variant="contained" fullWidth>
            Send password reset link
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
