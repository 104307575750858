import { CheckRounded } from "@mui/icons-material";
import {
  ListItemButton,
  ListItem,
  Icon,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import React from "react";
import { RegistrationStepModel } from "../../models/RegistrationStepsModel";
import { AppColors } from "../../common/AppColors";

interface Props {
  index: number;
  handleClickStep(index: number): void;
  step: RegistrationStepModel;
  disabled: boolean;
  completed: boolean;
  isCurrentStep: boolean;
}

export const RegistrationStep: React.FC<Props> = (props) => {
  let step = props.step;

  return (
    <ListItemButton
      onClick={() => props.handleClickStep(props.index)}
      disabled={props.disabled}
      sx={{
        p: 0,
        pl: 1,
        background: props.isCurrentStep ? AppColors.LightColor : "",
      }}
    >
      <ListItem
        secondaryAction={
          <Icon>{props.completed && <CheckRounded color="success" />}</Icon>
        }
      >
        <ListItemIcon>
          <Icon>
            <step.icon />
          </Icon>
        </ListItemIcon>
        <ListItemText primary={step.title} secondary={step.subtitle} />
      </ListItem>
    </ListItemButton>
  );
};
