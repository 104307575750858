import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";

export const filesAPI = createApi({
  reducerPath: "filesAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL + "files/",
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    postFormData: builder.mutation<
      any,
      { formData: FormData; directory: string }
    >({
      query: (params) => ({
        url: "upload?dir=" + params.directory,
        method: "POST",
        body: params.formData,
      }),
    }),
    deleteFile: builder.mutation({
      query: (filePath: string) => ({
        url: "delete",
        method: "DELETE",
        body: {
          path: filePath, // Ex. (uploads/1345556.png)
        },
      }),
    }),
  }),
});

export const { usePostFormDataMutation, useDeleteFileMutation } = filesAPI;
