import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const categoryProductPage: StyleModel = {
    mobile: {
        title: {
            position: "absolute",
            bottom: "10px",
            marginLeft: "4.83vw",
            color: AppColors.White,
          },
          favIcon: {
            ml: 1,
            verticalAlign: "middle"
          },
    },
    desktop: {
      title: {
        marginLeft: "7.29vw"
      }
    }
}