import {
  Button,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllCountriesQuery } from "../../services/AuthServices";
import { AuthTexts, localStorageKeys, snakeToCamel } from "../../common/Helper";
import { CountryModel } from "../../models/CountryModel";

interface Props {
  handleClickContinue(selectedCountry: CountryModel): void;
}

export const SelectCountryForm: React.FC<Props> = (props) => {
  const { data } = useGetAllCountriesQuery();
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryModel>();

  useEffect(() => {
    if (data && data.response) {
      let tempCountries = snakeToCamel(data.response) as CountryModel[];
      setCountries(tempCountries);
      setSelectedCountry(tempCountries[0]);
    }
  }, [data]);

  function handleSelectCountry(event: any) {
    let tempSelectedCountry = countries.find(
      (x) => x.id === event.target.value
    );
    setSelectedCountry(tempSelectedCountry!);
  }

  function handleClickContinue() {
    if (selectedCountry) {
      localStorage.setItem(
        localStorageKeys.country,
        JSON.stringify(selectedCountry)
      );
      props.handleClickContinue(selectedCountry);
    }
  }

  return (
    <DialogContent>
      <DialogTitle>{AuthTexts.selectYourCountry}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={selectedCountry ? selectedCountry.id : ""}
          onChange={handleSelectCountry}
        >
          {countries.length > 0 &&
            countries.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
        <Button
          onClick={handleClickContinue}
          variant="contained"
          sx={{ mt: 2 }}
          fullWidth
        >
          CONTINUE
        </Button>
      </DialogContent>
    </DialogContent>
  );
};
