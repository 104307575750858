import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Control, FormState, UseFormSetValue } from "react-hook-form";
import { ControlledTextField } from "../shared/ControlledTextField";
import { StatesSelect } from "../shared/StatesSelect";
import { StateModel } from "../../models/StateModel";
import { CheckoutAddressModel } from "../../models/CheckoutAddressModel";
import { AppColors } from "../../common/AppColors";
import { ProfileModel } from "../../models/ProfileModel";
import { Delete } from "@mui/icons-material";
import {
  useGetSavedDeliveryAddressesQuery,
  useRemoveDeliveryAddressMutation,
} from "../../services/CheckoutServices";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import useConfirm from "../../hooks/useConfirm";
import { SelectOptionModel } from "../../models/SelectOptionModel";
import { DeliveryAddressSelect } from "../shared/DeliveryAddressSelect";
import { DeliveryAddressSelectModel } from "../../models/DeliveryAddressSelectModel";

interface Props {
  control: Control<CheckoutAddressModel, any>;
  profile: ProfileModel;
  handleSelectState(state: StateModel): void;
  selectedStateId?: number;
  errors: FormState<CheckoutAddressModel>;
  isExpressShipping: boolean;
  setExpressShipping(_event: any, checked: boolean): void;
  isDeliveryApplicable: boolean;
  isSaveBillingAddress: boolean;
  setIsSaveBillingAddress(_event: any, checked: boolean): void;
  setValue: UseFormSetValue<CheckoutAddressModel>;
  isSaveDeliveryAddress: boolean;
  setIsSaveDeliveryAddress(_event: any, checked: boolean): void;
}

export const DeliveryAndBillingForm: React.FC<Props> = (props) => {
  const profile = props.profile;
  const control = props.control;
  const errors = props.errors.errors;

  const [isBillingAndDeliverySame, setIsBillingAndDeliverySame] =
    useState(true);
  const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] =
    useState<number>();
  const [selectedStateId, setSelectedStateId] = useState(props.selectedStateId);
  const [removeAddress] = useRemoveDeliveryAddressMutation();
  const { data: addressListResponse, refetch: refetchAddressList } =
    useGetSavedDeliveryAddressesQuery();
  const [addressModelList, setAddressModelList] = useState<
    DeliveryAddressSelectModel[]
  >([]);
  const [addressOptions, setAddressOptions] = useState<SelectOptionModel[]>([]);
  const dispath = useDispatch();
  const [ConfirmRemoveDialog, confirmRemove] = useConfirm(
    "Remove Address",
    "Are you sure do you want to remove this address?"
  );

  useEffect(() => {
    if (addressListResponse && addressListResponse.status === "success") {
      let addressList =
        addressListResponse.response as DeliveryAddressSelectModel[];
      let options: SelectOptionModel[] = [];
      addressList.forEach((address) => {
        let option: SelectOptionModel = {
          option: address.first_name + " - " + address.address1,
          value: address.id,
        };
        options.push(option);
      });
      setAddressOptions(options);
      setAddressModelList(addressList);
    }
  }, [addressListResponse]);

  function setDeliveryAddressFieldCommonValues(value = "") {
    props.setValue("deliveryAddress1", value);
    props.setValue("deliveryAddress2", value);
    props.setValue("deliveryFirstName", value);
    props.setValue("deliveryLastName", value);
    props.setValue("deliveryPhone", value);
  }

  function setSameAsBilling(_event: any, checked: boolean) {
    var deliveryDetailsValue = "";
    if (checked) {
      deliveryDetailsValue = "SAMEASBILLING";
      setSelectedDeliveryAddressId(undefined);
    } else {
      setSelectedStateId(profile.state?.id);
    }
    setDeliveryAddressFieldCommonValues(deliveryDetailsValue);
    setIsBillingAndDeliverySame(checked);
  }

  function handleSelectDeliverAddresss(address: DeliveryAddressSelectModel) {
    props.setValue("deliveryAddress1", address.address1);
    props.setValue("deliveryAddress2", address.address2);
    props.setValue("deliveryFirstName", address.first_name);
    props.setValue("deliveryLastName", address.last_name);
    props.setValue("deliveryPhone", address.contact_phone);
    props.setIsSaveDeliveryAddress("", false);
    setSelectedDeliveryAddressId(address.id);
    address.state_id && setSelectedStateId(address.state_id);
  }

  async function handleClickRemove() {
    if (selectedDeliveryAddressId) {
      const isConfirmed = await confirmRemove();
      if (isConfirmed) {
        dispath(setIsLoading(true));
        await removeAddress(selectedDeliveryAddressId);
        refetchAddressList();
        setSelectedDeliveryAddressId(undefined);
        setDeliveryAddressFieldCommonValues();
        dispath(setIsLoading(false));
      }
    }
  }

  return (
    <Card variant="outlined">
      <CardContent component={"form"}>
        <Grid container spacing={2}>
          <Grid item lg={7}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Billing Details
            </Typography>
          </Grid>
          {props.isDeliveryApplicable && (
            <Grid item lg={5} className="center-content">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.isExpressShipping}
                    onChange={props.setExpressShipping}
                  />
                }
                label={"Express Shipping"}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="First Name*"
              name="billingFirstName"
              error={errors.billingFirstName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Last Name*"
              name="billingLastName"
              error={errors.billingLastName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Phone*"
              name="billingPhone"
              error={errors.billingPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Billing Address Line 1*"
              name="billingAddress1"
              error={errors.billingAddress1}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Billing Address Line 2*"
              name="billingAddress2"
              error={errors.billingAddress2}
            />
          </Grid>
          {isBillingAndDeliverySame && (
            <Grid item xs={12}>
              <StatesSelect
                selectedStateId={props.selectedStateId!}
                getSelectedState={props.handleSelectState}
                countryId={profile.country.id}
                error={errors.stateName}
              />
            </Grid>
          )}
          <Grid item lg={5}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Delivery Details
            </Typography>
          </Grid>
          <Grid item lg={4} className="center-content">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBillingAndDeliverySame}
                  onChange={setSameAsBilling}
                />
              }
              label={"Same as billing details"}
            />
          </Grid>
          {!isBillingAndDeliverySame && (
            <>
              <Grid item lg={3} className="center-content">
                <DeliveryAddressSelect
                  selectedAddressId={selectedDeliveryAddressId}
                  setSelectedDeliveryAddress={handleSelectDeliverAddresss}
                  addressModelList={addressModelList}
                  addressOptions={addressOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label="First Name*"
                  name="deliveryFirstName"
                  error={errors.deliveryFirstName}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label="Last Name*"
                  name="deliveryLastName"
                  error={errors.deliveryLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label="Phone*"
                  name="deliveryPhone"
                  error={errors.deliveryPhone}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="deliveryAddress1"
                  label={"Delivery Address Line 1*"}
                  error={errors.deliveryAddress1}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="deliveryAddress2"
                  label={"Delivery Address Line 2*"}
                  error={errors.deliveryAddress2}
                />
              </Grid>
              <Grid item xs={12}>
                <StatesSelect
                  selectedStateId={selectedStateId!}
                  getSelectedState={(s) => {
                    setSelectedStateId(s.id);
                    props.handleSelectState(s);
                  }}
                  countryId={profile.country.id}
                  error={errors.stateName}
                />
              </Grid>
              <Grid item lg={12}>
                {selectedDeliveryAddressId ? (
                  <>
                    <Typography color={"red"} variant="caption" mr={2}>
                      Remove this address from address book.
                    </Typography>
                    <IconButton onClick={handleClickRemove}>
                      <Delete color="error" />
                    </IconButton>
                  </>
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.isSaveDeliveryAddress}
                        onChange={props.setIsSaveDeliveryAddress}
                      />
                    }
                    label={"Save delivery address for future orders."}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <ConfirmRemoveDialog />
    </Card>
  );
};
