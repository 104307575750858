import { colors } from "@mui/material";
import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const wishlistPage: StyleModel = {
  mobile: {
    title: {
      position: "absolute",
      top: "1vh",
      right: "0",
      left: "0",
      color: AppColors.White,
      textAlign: "center",
      margin: "auto",
      fontSize: "29px",
    },
    favIcon: {
      ml: 1,
      verticalAlign: "middle",
    },
    wrapper: {
      height: "71vh",
      borderRadius: "10px",
      overflow: "auto",
      p: 3,
      mt: -5,
      mb: 4,
      position: "relative",
      bgcolor: AppColors.White,
      boxShadow: "5px 5px 20px #00000029",
      display: "block",
    },
    alignCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    card: {
      wrapper: {
        display: "inline-flex",
        border: "none",
        borderRadius: "4px",
        width: "100%",
        "&: hover": {
          boxShadow: "5px 5px 20px #00000029",
        },
        cursor: "pointer",
      },
      clickableDiv: {
        width: "100%",
        position: "relative",
      },
      image: {
        objectFit: "contain",
        margin: "0",
        width: { xs: "25%", lg: "15%" },
        border: "1px solid " + AppColors.MainColor,
        borderRadius: "10px",
        m: 2,
      },
      content: {
        display: "block",
        width: "80%",
        height: "70%",
      },
      deleteButton: {
        position: "absolute",
        bgcolor: colors.red[700],
        right: "10px",
        top: "10px",
        color: AppColors.White,
        textTransform: "capitalize",
        "&:hover": {
          bgcolor: AppColors.Blue,
        },
      },
      buyButton: {
        width: "40%",
        borderRadius: 1.5,
        fontWeight: "bold",
        marginLeft: "auto",
        display: "none",
        mt: -3,
      },
      line: {
        m: 1,
      },
    },
    wishlistEmpty: {
      bgcolor: AppColors.LightColor,
      color: AppColors.MainColor,
      p: 3,
      textAlign: "center",
      borderRadius: 3,
    },
  },
  desktop: {
    wrapper: {
      mt: -8,
    },
    card: {
      buyButton: {
        display: "block",
      },
    },
  },
};
