import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";

export const categoryAPI = createApi({
  reducerPath: "categoryAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL + "categories/",
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCategories: builder.query<ResponseModel, void>({
      query: () => "all",
    }),
  }),
});

export const { useGetAllCategoriesQuery } = categoryAPI;
