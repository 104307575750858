import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ControlledTextField } from "../components/shared/ControlledTextField";
import { LoginFormModel } from "../models/LoginFormModel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../schemas/LoginSchema";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthTexts,
  appTitle,
  localStorageKeys,
  showToastMessage,
  snakeToCamel,
} from "../common/Helper";
import { StyleContext } from "../context/StyleContextProvider";
import { usePostLoginCredentialsMutation } from "../services/AuthServices";
import Footer from "../components/shared/Footer";
import { useDispatch } from "react-redux";
import { ProfileModel } from "../models/ProfileModel";
import { setProfile, setToken } from "../reduxenv/Slices/ProfileSlice";
import { ResponseModel } from "../models/ResponseModel";
import { LocalStorage } from "../common/Localstorage";
import { Helmet } from "react-helmet-async";
import { RequestResetPassword } from "../components/Auth/resetPassword/RequestResetPassword";
import { ToastMessages } from "../common/ToastMessages";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState<any>(
    styleContext.getComponentStyle("auth")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const [postLoginCredentials, { isLoading }] =
    usePostLoginCredentialsMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormModel>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [showResetPassword, setShowResetPassword] = useState(false);

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("auth"));
  }, [isMobile]);

  const submit = async (loginFormData: LoginFormModel) => {
    postLoginCredentials(loginFormData)
      .then((data: any) => {
        let response = data.data as ResponseModel;
        if (response) {
          let profile = snakeToCamel(response.response) as ProfileModel;
          localStorage.setItem(
            localStorageKeys.profile,
            JSON.stringify(profile)
          );
          if (response.status === "success") {
            LocalStorage.updateLoggedInStatus(true, profile.token);
            LocalStorage.updateSignatureStatus(profile.isPendingSignatures);
            var callbackUrl = LocalStorage.getCallBackURL();
            if (callbackUrl) {
              navigate(callbackUrl);
              LocalStorage.removeCallBackURL();
            } else {
              navigate("/home");
            }
          }
          dispatch(setToken(profile.token));
          dispatch(setProfile(profile));
        }
      })
      .catch((error) => {});
  };

  function toggleShowResetPassword() {
    setShowResetPassword(!showResetPassword);
  }

  return (
    <>
      <Helmet>
        <title>{`${appTitle} - Account`}</title>
      </Helmet>
      <Header />
      <div style={styles.wallpaper}></div>
      <Container component={"main"} maxWidth="sm" sx={styles.loginContainer}>
        <Card variant="outlined" sx={{ borderRadius: 1 }}>
          <CardContent
            sx={{ p: 4 }}
            component={"form"}
            onSubmit={handleSubmit(submit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="email"
                  label="Email Address"
                  error={errors.email}
                  inputProps={{
                    type: "email",
                    autoFocus: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="password"
                  label="Password"
                  error={errors.password}
                  inputProps={{
                    type: "password",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {AuthTexts.forgotPassword}
                  <Link to="#" onClick={toggleShowResetPassword}>
                    Reset password
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                size="large"
                sx={{ mt: 3, p: 2, fontWeight: "bold" }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={26} />
                ) : (
                  "LOGIN"
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {AuthTexts.noAccount}
                <Link to="/auth/register">Create account</Link>
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Dialog open={showResetPassword} onClose={toggleShowResetPassword}>
        <RequestResetPassword handleClose={toggleShowResetPassword} />
      </Dialog>
      <Footer />
    </>
  );
};

export default LoginPage;
