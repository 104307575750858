import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Wallpaper } from "../components/shared/Wallpaper";
import { StyleContext } from "../context/StyleContextProvider";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../components/shared/ControlledTextField";
import { ResetPasswordFormModel } from "../models/ResetPasswordFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordFormSchema } from "../schemas/ResetPasswordFormSchema";
import {
  useSetNewPasswordMutation,
  useValidateResetTokenMutation,
} from "../services/AuthServices";
import { useNavigate, useParams } from "react-router-dom";
import { showToastMessage } from "../common/Helper";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../models/ResponseModel";
import { Images } from "../common/Images";
import { LocalStorage } from "../common/Localstorage";
import useConfirm from "../hooks/useConfirm";
import { setProfile } from "../reduxenv/Slices/ProfileSlice";

export const SetNewPasswordPage: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("resetPassword")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormModel>({
    resolver: yupResolver(ResetPasswordFormSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const [setNewPassword] = useSetNewPasswordMutation();
  const [validateToken] = useValidateResetTokenMutation();
  const [userEmail, setUserEmail] = useState<string>();
  const [LogoutAlert, promptLogout] = useConfirm(
    "Active Session Detected",
    "To set a password, please log out of your current session. Are you sure you want to proceed with logging out?"
  );

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("resetPassword"));
  }, [isMobile]);

  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
      showToastMessage("error", "Token is missing!");
    } else {
      checkActiveSession();
    }
  }, []);

  async function checkActiveSession() {
    if (LocalStorage.getLoggedInStatus()) {
      if (await promptLogout()) {
        LocalStorage.clearStorage();
        dispatch(setProfile(undefined));
      } else {
        navigate("/home");
        return;
      }
    }
    validateToken(token)
      .then((res: any) => {
        let response = res.data as ResponseModel;
        if (response.status != "success") {
          navigate("/");
          showToastMessage("error", response.message);
        } else {
          setUserEmail(response.response.email);
        }
      })
      .catch(() => {
        navigate("/");
      });
  }

  async function submit(data: ResetPasswordFormModel) {
    if (token) {
      dispatch(setIsLoading(true));
      setNewPassword({ newPassword: data.confirmPassword, token: token })
        .then((res: any) => {
          let response = res.data as ResponseModel;
          showToastMessage(response.status, response.message);
          dispatch(setIsLoading(false));
          navigate("/auth/login");
        })
        .catch((er) => {
          dispatch(setIsLoading(false));
          navigate("/auth/login");
        });
    }
  }

  return (
    <>
      <Header />
      <Grid container spacing={2} justifyContent={"center"}>
        <Wallpaper
          content={
            <Typography sx={styles.title} variant="h4">
              Set New Password{" "}
              <Typography variant="body1">Email: {userEmail}</Typography>
            </Typography>
          }
        />
        <Grid xs={12} lg={5} xl={4} item>
          <Card sx={styles.form}>
            <CardContent>
              <Grid
                container
                spacing={2}
                component={"form"}
                onSubmit={handleSubmit(submit)}
              >
                <Grid item xs={12}>
                  <ControlledTextField
                    name="password"
                    label="Password"
                    control={control}
                    inputProps={{ type: "password" }}
                    error={errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField
                    name="confirmPassword"
                    label="Confirm Passord"
                    control={control}
                    inputProps={{ type: "password" }}
                    error={errors.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth type="submit" variant="contained">
                    Set Password
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4} xl={3} sx={styles.imageWrapper}>
          <Avatar
            sx={styles.image}
            variant="square"
            src={Images.forgotPassword}
          />
        </Grid>
      </Grid>
      <LogoutAlert />
      <Footer />
    </>
  );
};
