import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    show: false,
  },
  reducers: {
    setShowCart: (state, action: { payload: boolean }) => {
      state.show = action.payload;
    },
  },
});

export const { setShowCart } = cartSlice.actions;
export const isShowCart = (state: any) => state.cart.show as boolean;
export default cartSlice.reducer;
