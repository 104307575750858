import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { ControlledTextField } from "../shared/ControlledTextField";
import { ResellerFormModel } from "../../models/ResellerFormModel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResellerFormSchema } from "../../schemas/ResellerFormSchema";
import { SaveRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getResellerFormData,
  setResellerFormData,
} from "../../reduxenv/Slices/ResellerFormSlice";
import { localStorageKeys } from "../../common/Helper";
import { AppColors } from "../../common/AppColors";
import { RegistrationStepIndexes } from "../../common/Enums";

interface Props {
  handleGoToNextStep(nextStepIndex: number): void;
}

const ResellerForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const resellerFormData = useSelector(getResellerFormData);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResellerFormModel>({
    resolver: yupResolver(ResellerFormSchema),
    defaultValues: resellerFormData
      ? resellerFormData
      : {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          password: "",
          confirmPassword: "",
        },
  });

  const submit = async (reseller: ResellerFormModel) => {
    dispatch(setResellerFormData(reseller));
    await localStorage.setItem(
      localStorageKeys.reseller,
      JSON.stringify(reseller)
    );
    props.handleGoToNextStep(RegistrationStepIndexes.Company);
  };

  return (
    <Card
      sx={{ p: 2 }}
      variant="outlined"
      component={"form"}
      onSubmit={handleSubmit(submit)}
    >
      <CardContent>
        <Typography mt={2} variant="h4" color={AppColors.Blue}>
          About You
        </Typography>
        <Divider />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="firstName"
              label="First Name*"
              error={errors.firstName}
              inputProps={{
                autoFocus: !resellerFormData,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="lastName"
              label="Last Name*"
              error={errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="phone"
              label="Phone*"
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="email"
              label="Email Address*"
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="password"
              label="Password*"
              error={errors.password}
              inputProps={{ type: "password" }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="confirmPassword"
              label="Confirm Password*"
              error={errors.confirmPassword}
              inputProps={{ type: "password" }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          color="success"
          sx={{ mt: 2, pt: 1, pl: 4, pr: 4, ml: "auto", display: "flex" }}
          variant="contained"
          endIcon={<SaveRounded />}
        >
          SAVE
        </Button>
      </CardContent>
    </Card>
  );
};

export default ResellerForm;
