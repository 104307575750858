import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { AppColors } from "../../common/AppColors";
import { Delete } from "@mui/icons-material";
import {
  useDeleteFileMutation,
  usePostFormDataMutation,
} from "../../services/FileServices";
import { ResponseModel } from "../../models/ResponseModel";
import { ToastMessages } from "../../common/ToastMessages";
import { FileModel } from "../../models/FileModel";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { showToastMessage } from "../../common/Helper";
import { validateFile } from "../../common/FileValidation";

interface Props {
  name: string;
  label: string;
  selectedFile: FileModel;
  setSelectedFile(file?: FileModel): void;
}

export const FileUploadButton: React.FC<Props> = (props) => {
  let selectedFile = props.selectedFile;
  const dispatch = useDispatch();
  const [uploadFile] = usePostFormDataMutation();
  const [deleteFile] = useDeleteFileMutation();

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      if (event.target.files) {
        let file = event.target.files[0];
        if (validateFile(file.name)) {
          dispatch(setIsLoading(true));
          let formData = new FormData();
          formData.append("file", file);
          uploadFile({ formData: formData, directory: "" })
            .then((data: any) => {
              let response = data.data as ResponseModel;
              if (response.status === "success") {
                let fileModel = response.response as FileModel;
                props.setSelectedFile(fileModel);
              }
              dispatch(setIsLoading(false));
            })
            .catch((error) => {
              ToastMessages.thereWasAnError();
            });
        }
      }
    } catch {
      ToastMessages.thereWasAnError();
      dispatch(setIsLoading(false));
    }
  }

  async function deleteUploadedFile() {
    try {
      deleteFile(selectedFile.path)
        .then((data: any) => {
          let response = data.data as ResponseModel;
          if (response.status === "success") {
            props.setSelectedFile(undefined);
          } else {
            ToastMessages.thereWasAnError();
          }
        })
        .catch((error) => {
          ToastMessages.thereWasAnError();
        });
    } catch {
      ToastMessages.thereWasAnError();
    }
  }

  return (
    <>
      {selectedFile ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Typography variant="caption" color={AppColors.MainColor}>
            {selectedFile.name}
          </Typography>
          <IconButton onClick={deleteUploadedFile}>
            <Delete fontSize="small" color="error" />
          </IconButton>
        </Box>
      ) : (
        <>
          <input
            type="file"
            hidden
            name={props.name}
            id={props.name}
            onChange={handleFileChange}
            accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel"
          />
          <Typography
            sx={{
              border: "1px solid " + AppColors.MainColor,
              p: 2,
              borderRadius: 2,
              cursor: "pointer",
              display: "block",
              width: "90%",
            }}
            variant="button"
            htmlFor={props.name}
            component={"label"}
          >
            {props.label}
          </Typography>
        </>
      )}
    </>
  );
};
