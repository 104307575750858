import React from "react";
import ProductCard from "../shared/ProductCard";
import { useSelector } from "react-redux";
import { getProfile } from "../../reduxenv/Slices/ProfileSlice";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { AppColors } from "../../common/AppColors";
import { ProductModel } from "../../models/ProductModel";
import { ProductsLoader } from "../Loaders/ProductsLoader";

interface Props {
  title: string;
  productList: ProductModel[];
  isFetching: boolean;
  error: any;
  slidesPerRow: number;
  rows: number;
  enableNoProductsMessage?: boolean;
}

const ProductSlider: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery("(max-width: 786px)");
  const profile = useSelector(getProfile);
  let productList = props.productList;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0%",
    dots: true,
    rows: props.rows,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: isMobile ? 4000 : 8000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: isMobile ? 2 : props.slidesPerRow,
    useTransform: false,
  };

  return (
    <Box overflow={"hidden"} pb={4}>
      <Typography color={AppColors.Blue} variant="h5" mb={2}>
        {props.title}
      </Typography>
      {props.isFetching ? (
        <ProductsLoader />
      ) : productList && productList.length > 0 ? (
        productList.map((product, index) => (
          <ProductCard
            key={index}
            product={product}
            currency={profile.country.currency}
          />
        ))
      ) : (
        props.enableNoProductsMessage && (
          <Typography
            variant="subtitle1"
            color={AppColors.MainColor}
            sx={{
              textAlign: "center",
              bgcolor: AppColors.LightColor,
              p: 4,
              borderRadius: "8px",
              mt: { xs: "5vh", lg: "6vh" },
              mb: { xs: "5vh", lg: "6vh" },
              maxWidth: "400px",
              margin: "auto"
            }}
          >
            No products found!
          </Typography>
        )
      )}
    </Box>
  );
};

export default ProductSlider;
