import React from "react";
import { deliveryStatuses } from "../../models/OrderModel";
import { Slider, SliderThumb, Typography, styled } from "@mui/material";
import { getKeyByValue } from "../../common/Helper";
import { AppColors } from "../../common/AppColors";

interface Props {
  status: string;
}

const statusAndValues: { [key: number]: string } = {
  0: deliveryStatuses.processing,
  100: deliveryStatuses.shipped,
};

const StatusSlider = styled(Slider)(() => ({
  color: AppColors.Blue,
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 30,
    width: 30,
    backgroundColor: AppColors.MainColor,
    border: "1px solid " + AppColors.DarkGray,
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
  },
  "& .MuiSlider-track": {
    height: 5,
  },
}));

function MarkLabel(label: string) {
  return (
    <Typography variant="subtitle2" color={AppColors.Blue} fontWeight={"bold"}>
      {label}
    </Typography>
  );
}

interface SliderThumbComponentProps extends React.HTMLAttributes<unknown> {}

export const CurrentDeliveryStatus: React.FC<Props> = (props) => {
  let sliderValue: number = getKeyByValue(statusAndValues, props.status) ?? 0;

  function ThumbComponent(thumpProps: SliderThumbComponentProps) {
    const { children, ...other } = thumpProps;
    return <SliderThumb {...other}>{children}</SliderThumb>;
  }

  return (
    <StatusSlider
      slots={{ thumb: ThumbComponent }}
      value={sliderValue}
      step={50}
      marks={[
        { value: 0, label: MarkLabel(deliveryStatuses.processing) },
        { value: 100, label: MarkLabel(deliveryStatuses.shipped) },
      ]}
      min={0}
      max={100}
      size="medium"
      className="delivery-status"
    />
  );
};
