import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { appTitle } from "../common/Helper";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Wallpaper } from "../components/shared/Wallpaper";
import { SearchBar } from "../components/shared/SearchBar";
import { StyleContext } from "../context/StyleContextProvider";
import { Container, Grid, colors, useMediaQuery } from "@mui/material";
import {
  NavLinkModel,
  NavigationLink,
} from "../components/Home/NavigationLink";
import {
  CategoryOutlined,
  FavoriteOutlined,
  LocalFireDepartmentOutlined,
  PercentOutlined,
} from "@mui/icons-material";
import { Images } from "../common/Images";

export const HomePage: React.FC = () => {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("homePage")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const navLinks: NavLinkModel[] = [
    {
      title: "All Products",
      link: "/explore",
      icon: CategoryOutlined,
      bgColor: colors.blue[700],
      image: Images.categories,
    },
    {
      title: "On Sale",
      link: "/explore?featured=1",
      icon: PercentOutlined,
      bgColor: colors.green[700],
      image: Images.featuredProducts,
    },
    {
      title: "New Arrivals",
      link: "/explore",
      icon: LocalFireDepartmentOutlined,
      bgColor: colors.amber[700],
      image: Images.latestProducts,
    },
    {
      title: "My Favourites",
      link: "/favourites",
      icon: FavoriteOutlined,
      bgColor: colors.red[700],
      image: Images.wishlist,
    },
  ];

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("homePage"));
  }, [isMobile]);

  return (
    <>
      <Helmet>
        <title>{appTitle + " - Home"}</title>
      </Helmet>
      <Header hideSearchBar />
      <Wallpaper content={<SearchBar styles={styles.search} />} />
      <Container maxWidth={"xl"}>
        <Grid container mt={4} mb={6} spacing={4} justifyContent={"center"}>
          {navLinks.map((item, index) => (
            <NavigationLink key={index} linkModel={item} />
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
