import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";

export const appAPI = createApi({
  reducerPath: "appAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL,
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    refetchUserData: builder.query<ResponseModel, void>({
      query: () => "/reseller/refetch",
      keepUnusedDataFor: 0.0001,
    }),
    submitSignatures: builder.mutation({
      query: (signatures: { name: string; path: string }[]) => ({
        url: "/company/submit-signatures",
        method: "POST",
        body: {
          signatures: signatures,
        },
      }),
    }),
  }),
});

export const { useRefetchUserDataQuery, useSubmitSignaturesMutation } = appAPI;
