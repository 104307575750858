import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

const useMessage = (): [React.FC, (title: string, message: string) => void] => {
  const [messageData, setMessageData] = useState<{title: string, message: string} | null>(null);

  const handleClose = () => {
    setMessageData(null);
  };
  const showMessage = (title: string, message: string) => {    
    setMessageData({ title: title, message: message });
  };

  const Message: React.FC = () => (
    <Dialog
      maxWidth={"xs"}
      open={messageData !== null}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>{messageData?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{messageData?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant={"contained"} onClick={handleClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );

  return [Message, showMessage];
};

export default useMessage;
