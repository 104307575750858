import { AppColors } from "../common/AppColors";
import { StyleModel } from "../models/StyleModel";

export const errorPage: StyleModel = {
  mobile: {
    container: {
      width: "100%",
      height: "100vh",
      backgroundColor: AppColors.Blue    
    },
    content: {
        position: "relative",
        zIndex: "1"
    },
    heading: {
        color: AppColors.White,
        textAlign: "center",
        marginTop: "-15vh"
    },
    subheading: {
        color: AppColors.White,
        textAlign: "center",
        textTransform: "uppercase"
    },
    goHome: {
        bgcolor: AppColors.DarkGray,
        color: AppColors.Black,
        p: 2,
        borderRadius: 10,
        boxShadow: "none",
        "&: hover": {
            color: AppColors.White
        },
        display: "flex",   
        margin: "35vh auto 0 auto",
        fontWeight: "bold"  
    },
    svg: {
        position: "absolute",
        height: "100%",
        width: "100%",
        objectFit: "cover"
    }
  },
  desktop: {},
};
