import { BoltRounded, SortRounded, Thunderstorm } from "@mui/icons-material";

export type SortProductModel = {
  name: string;
  value: string;
  icon: any;
};

export const sortMethods: SortProductModel[] = [
  {
    name: "Default",
    value: "priority-desc",
    icon: Thunderstorm,
  },
  {
    name: "New Arrivals",
    value: "id-desc",
    icon: BoltRounded,
  },
  // {
  //   name: "Name Asc",
  //   value: "name-asc",
  //   icon: Icons.sort_by_alpha_rounded,
  // },
  // {
  //   name: "Name Desc",
  //   value: "name-desc",
  //   icon: Icons.sort_by_alpha_rounded,
  // },
  {
    name: "Price Ascending",
    value: "price-asc",
    icon: SortRounded,
  },
  {
    name: "Price Descending",
    value: "price-desc",
    icon: SortRounded,
  },
];
