import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Container, Grid, Skeleton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { CurrentDeliveryStatus } from "../components/Tracking/CurrentDeliveryStatus";
import { TrackingDetails } from "../components/Tracking/TrackingDetails";
import { snakeToCamel } from "../common/Helper";
import { TrackingDetailsModel } from "../models/TrackingDetailsModel";
import { useGetTrackingDataQuery } from "../services/ProductTrackingServices";
import { deliveryStatuses } from "../models/OrderModel";
import { AppColors } from "../common/AppColors";
import { Helmet } from "react-helmet-async";

export const ProductTrackingPage: React.FC = () => {
  const { trackingId } = useParams();
  const { data: trackingResponse } = useGetTrackingDataQuery(trackingId);
  const [details, setDetails] = useState<TrackingDetailsModel>();
  const orderCanceled = details?.status === deliveryStatuses.canceled;

  useEffect(() => {
    if (trackingResponse && trackingResponse.status === "success") {
      let tempDetails = snakeToCamel(
        trackingResponse?.response
      ) as TrackingDetailsModel;
      setDetails(tempDetails);
    }
  }, [trackingResponse]);

  return (
    <>
      <Helmet>
        <title>{"#" + details?.orderId + " - " + details?.name}</title>
      </Helmet>
      <Header />
      <Grid container>
        <Container maxWidth={"lg"}>
          <Grid container spacing={2} mt={4} justifyContent={"center"} mb={4}>
            <Grid item xs={10} lg={7}>
              <CurrentDeliveryStatus status={details?.status!} />
            </Grid>
            {details ? (
              <>
                {orderCanceled ? (
                  <Grid item xs={12} mb={5}>
                    <Typography
                      variant="h4"
                      color={AppColors.MainColor}
                      textAlign={"center"}
                    >
                      Your Order has been Canceled!
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Skeleton variant="text" width={"100%"} height={200} />
              </Grid>
            )}
            <Grid item xs={12} lg={7}>
              {details && <TrackingDetails details={details} />}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
