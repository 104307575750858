import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Slider,
  SxProps,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AppColors } from "../../common/AppColors";
import { sortMethods } from "../../models/SortProductsModel";
import { PriceRangeModel } from "../../models/PriceRangeModel";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";

interface Props {
  featured: number;
  selectedSortMethod: string;
  setFeatured(value: number): void;
  setSelectedSortMethod(value: string): void;
  priceRange: PriceRangeModel;
  setPriceRange(value: PriceRangeModel): void;
}

const gridStyles: SxProps = {
  pl: 2,
  pr: 1,
  pt: 0.8,
  pb: 0.8,
  bgcolor: AppColors.LightGray,
  borderRadius: "8px",
  border: "2px solid " + AppColors.DarkGray,
  mt: {xs: 2, lg: 0}
};
const defaultMaxPrice = 10000;

export const SortByPanel: React.FC<Props> = (props) => {
  let priceRange = props.priceRange;
  const [selectedPriceRange, setSelectedPriceRange] = useState([
    priceRange.minPrice,
    priceRange.maxPrice,
  ]);
  const { formatCurrency } = useFormatCurrency();

  const handleChange = (_e: Event, values: number | number[]) => {
    let range = values as number[];
    if (range.length > 0) {
      setSelectedPriceRange(range);
      props.setPriceRange({
        minPrice: range[0],
        maxPrice: range[1] === defaultMaxPrice ? 0 : range[1],
      });
    }
  };

  const priceRangeText = () => {
    let minText = formatCurrency(selectedPriceRange[0]);
    let text = "";
    if (
      selectedPriceRange[0] === 0 &&
      selectedPriceRange[1] === defaultMaxPrice
    ) {
      text = "Any Price";
    } else if (selectedPriceRange[1] === defaultMaxPrice) {
      text = minText + " and above";
    } else {
      text = minText + " - " + formatCurrency(selectedPriceRange[1]);
    }
    return text;
  };

  return (
    <Box mt={1} ml={{ xs: 0, lg: 1 }} mb={2}>
      <Grid container width={"100%"}>
        <Grid item xs={12} lg={2.5} mr={1}>
          <Select
            sx={{
              p: 0,
              borderRadius: "10px",
              height: "45px",
              color: AppColors.Black,
              border: "1px solid " + AppColors.DarkGray,
              borderLeft: "none"
            }}
            fullWidth
            value={props.selectedSortMethod}
            onChange={(e) => {
              props.setSelectedSortMethod(e.target.value);
            }}
          >
            {sortMethods.map((sort, index) => (
              <MenuItem key={index} value={sort.value}>
                <Typography variant="subtitle1">{sort.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          mr={1}
          className="center-content"
          sx={{ marginLeft: "auto", ...gridStyles }}
          mt={{xs: 2, lg: 0}}
        >
          <Box
            sx={{
              width: "100%",
              pr: 2,
              pl: 2,
            }}
          >
            <Slider
              size={"small"}
              sx={{
                flexShrink: 0,
                width: "90%",
                display: "block",
                margin: "auto",
                padding: "10px",
              }}
              value={selectedPriceRange}
              min={0}
              max={10000}
              valueLabelDisplay="auto"
              onChange={handleChange}
              marks
              step={1000}
            />
            <Typography
              variant="subtitle2"
              textAlign={"center"}
              display={"block"}
            >
              Price Range ({priceRangeText()})
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={2.5} sx={gridStyles}>
          <FormControlLabel
            sx={{
              width: "100%",
            }}
            control={
              <Checkbox
                checked={props.featured === 1}
                onChange={(e) => props.setFeatured(e.target.checked ? 1 : 0)}
              />
            }
            label={
              <Typography variant="subtitle2">On Sale Products</Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
