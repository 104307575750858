import * as yup from "yup";

export const DeliveryAndBillingFormSchema = yup.object().shape({
  billingFirstName: yup.string().required().label("First Name"),
  billingLastName: yup.string().required().label("Last Name"),
  billingPhone: yup.string().required().label("Phone"),
  billingAddress1: yup.string().required().label("Billing Address Line 1"),
  billingAddress2: yup.string().required().label("Billing Address Line 2"),

  deliveryFirstName: yup.string().required().label("First Name"),
  deliveryLastName: yup.string().required().label("Last Name"),
  deliveryPhone: yup.string().required().label("Phone"),
  deliveryAddress1: yup.string().required().label("Delivery Address Line 1"),
  deliveryAddress2: yup.string().required().label("Delivery Address Line 2"),
  
  stateName: yup.string().required().label("State"),
});
