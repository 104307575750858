import { createSlice } from "@reduxjs/toolkit";
import { ResellerFormModel } from "../../models/ResellerFormModel";
import { localStorageKeys } from "../../common/Helper";

function checkResellerFormFilled() {
    let resellerFormDataString = localStorage.getItem(localStorageKeys.reseller);
    if (resellerFormDataString) {
        let resellerFormInitilaData: ResellerFormModel = JSON.parse(resellerFormDataString);
        return resellerFormInitilaData;
    }
}

const resellerFormData = createSlice({
    name: "resellerFormData",
    initialState: {
        value: checkResellerFormFilled(),
    },
    reducers: {
        setResellerFormData: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setResellerFormData } = resellerFormData.actions;
export const getResellerFormData = (state: any) =>
    state.resellerFormData.value as ResellerFormModel;
export default resellerFormData.reducer;
