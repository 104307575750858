import { Dialog } from "@mui/material";
import { useState } from "react";

interface inputDialogProps {
  cancel(): void;
  proceed(data: any): void;
}

const useInputDialog = (
  InputDialogForm: React.FC<inputDialogProps>
): [React.FC, () => Promise<any | undefined>] => {
  const [promise, setPromise] = useState<{
    resolve: (value: any | undefined) => void;
  } | null>(null);

  const getInputData = () =>
    new Promise<any | undefined>((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleProceed = (data: any) => {
    promise?.resolve(data);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(undefined);
    handleClose();
  };

  const InputDialog: React.FC = () => (
    <Dialog
      maxWidth={"xs"}
      open={promise !== null}
      fullWidth
      onClose={handleCancel}
    >
      <InputDialogForm cancel={handleCancel} proceed={handleProceed} />
    </Dialog>
  );
  return [InputDialog, getInputData];
};

export default useInputDialog;
