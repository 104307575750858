import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { SignupCompanyModel } from "../models/SignupCompanyModel";
import { LoginFormModel } from "../models/LoginFormModel";
import { ResponseModel } from "../models/ResponseModel";

export const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseURL }),
  endpoints: (builder) => ({
    postLoginCredentials: builder.mutation({
      query: (body: LoginFormModel) => ({
        url: "/reseller/login",
        method: "POST",
        body: body,
      }),
    }),
    postSignupDetails: builder.mutation({
      query: (body: SignupCompanyModel) => ({
        url: "/company/signup",
        method: "POST",
        body: body,
      }),
    }),
    getAllCountries: builder.query<any, void>({
      query: () => "/countries/all",
    }),
    getAgreementFilePath: builder.query<
      ResponseModel,
      { countryId: number; companyId: number }
    >({
      query: (params) =>
        `company/agreement/${params.countryId}/${params.companyId}`,
    }),
    validateResetToken: builder.mutation<ResponseModel, any>({
      query: (token) => ({
        url: "/reseller/validate-reset-token",
        method: "POST",
        body: {
          token: token,
        },
      }),
    }),
    requestResetPassword: builder.mutation<ResponseModel, any>({
      query: (email: string) => ({
        url: "/reseller/request-reset-password",
        method: "POST",
        body: {
          email: email,
        },
      }),
    }),
    setNewPassword: builder.mutation<
      ResponseModel,
      { newPassword: string; token: string }
    >({
      query: (params) => ({
        url: "/reseller/set-new-password",
        method: "PUT",
        body: {
          password: params.newPassword,
          token: params.token,
        },
      }),
    }),
    getAllStates: builder.query<ResponseModel, { countryId: number }>({
      query: (params) => `/states/all/${params.countryId}`,
    }),
  }),
});

export const {
  usePostLoginCredentialsMutation,
  useGetAllCountriesQuery,
  usePostSignupDetailsMutation,
  useGetAgreementFilePathQuery,
  useRequestResetPasswordMutation,
  useSetNewPasswordMutation,
  useValidateResetTokenMutation,
  useGetAllStatesQuery
} = authAPI;
