import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Dialog,
  Drawer,
  FormHelperText,
  List,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { RegistrationStepModel } from "../models/RegistrationStepsModel";
import {
  BusinessRounded,
  DocumentScannerRounded,
  HandshakeRounded,
  LocalShippingRounded,
  PersonRounded,
  TollRounded,
} from "@mui/icons-material";
import ResellerForm from "../components/Auth/ResellerForm";
import { StyleContext } from "../context/StyleContextProvider";
import { localStorageKeys } from "../common/Helper";
import CompanyForm from "../components/Auth/CompanyForm";
import { SelectCountryForm } from "../components/Auth/SelectCountryForm";
import { CountryModel } from "../models/CountryModel";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedCountry,
  setSelectedCountry,
} from "../reduxenv/Slices/SelectedCountrySlice";
import { RegistrationStep } from "../components/Auth/RegistrationStep";
import { DirectorsDetails } from "../components/Auth/directors/DirectorsDetails";
import { LocalStorage } from "../common/Localstorage";
import CreditRequestForm from "../components/Auth/CreditRequestForm";
import TermsAndConditionsForm from "../components/Auth/TermsAndConditionsForm";
import { SupplierDetails } from "../components/Auth/suppliers/SupplierDetails";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import { RegistrationStepIndexes } from "../common/Enums";
import useConfirm from "../hooks/useConfirm";

const RegisterPage: React.FC = () => {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState<any>(
    styleContext.getComponentStyle("auth")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const [showSteps, setShowSteps] = useState(!isMobile);
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getSelectedCountry);
  const [cachedStepKeys, setCachedStepKeys] = useState<string[]>([]);
  const [ResetAlert, confirmReset] = useConfirm(
    "Reset",
    "Are you sure you want to clear all entered details?"
  );
  const registrationSteps: RegistrationStepModel[] = [
    {
      title: "About you",
      subtitle: "Name, Address, Contact Details",
      icon: PersonRounded,
      component: <ResellerForm handleGoToNextStep={handleGoToNextStep} />,
      key: localStorageKeys.reseller,
    },
    {
      title: "About Your Company",
      subtitle: "Basic Details",
      icon: BusinessRounded,
      component: <CompanyForm handleGoToNextStep={handleGoToNextStep} />,
      key: localStorageKeys.company,
    },
    {
      title: "Credit Request (Optional)",
      subtitle: "Place orders for credits.",
      icon: TollRounded,
      component: <CreditRequestForm handleGoToNextStep={handleGoToNextStep} />,
      key: localStorageKeys.credits,
    },
    {
      title: "Directors Details",
      subtitle: "Directors Details",
      icon: HandshakeRounded,
      component: <DirectorsDetails handleGoToNextStep={handleGoToNextStep} />,
      key: localStorageKeys.directors,
    },
    {
      title: "Trade References",
      subtitle: "Major Suppliers Details",
      icon: LocalShippingRounded,
      component: <SupplierDetails handleGoToNextStep={handleGoToNextStep} />,
      key: localStorageKeys.suppliers,
    },
    {
      title: "Terms & Conditions",
      subtitle: "Agree to our terms & conditions.",
      icon: DocumentScannerRounded,
      component: <TermsAndConditionsForm />,
      key: localStorageKeys.terms,
    },
  ];
  const [currentStep, setCurrentStep] = useState<RegistrationStepModel>(
    registrationSteps[0]
  );

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("auth"));
  }, [isMobile]);

  useEffect(() => {
    // This is to skip the completed steps and straightly go for next step!!
    async function checkIntialStep() {
      let filteredKeys = await handleSetCachedStepKeys();
      let tempCurrentStep = registrationSteps[0];
      registrationSteps.forEach((step, stepIndex) => {
        for (let i = 0; i < filteredKeys.length; i++) {
          if (step.key === filteredKeys[i]) {
            tempCurrentStep = registrationSteps[stepIndex + 1];
          }
        }
      });
      setCurrentStep(tempCurrentStep);
    }
    checkIntialStep();
  }, []);

  function handleSelectCountry(selectedCountry: CountryModel) {
    dispatch(setSelectedCountry(selectedCountry));
    setCurrentStep(registrationSteps[0]);
    setCachedStepKeys([localStorageKeys.country]);
  }

  function toggleStepsDrawer() {
    setShowSteps(!showSteps);
  }

  function handleClickRegistrationStep(index: number) {
    if (isMobile) {
      toggleStepsDrawer();
    }
    var tempCurrentStep = registrationSteps[index];
    setCurrentStep(tempCurrentStep);
  }

  async function handleGoToNextStep(nextStepIndex: number) {
    var nextStep = registrationSteps[nextStepIndex];
    if (nextStep) {
      await handleSetCachedStepKeys();
      setCurrentStep(nextStep);
    }
  }

  async function handleSetCachedStepKeys() {
    let keys: string[] = await LocalStorage.getLocalStorageKeys();
    let enumStrigs = Object.values(RegistrationStepIndexes) as string[];
    let filteredKeys = keys.filter((key) =>
      enumStrigs.some(
        (enumValue: string) =>
          enumValue.toString().toLowerCase() === key.toLowerCase()
      )
    );
    setCachedStepKeys(filteredKeys);
    return filteredKeys;
  }

  async function handleClickReset() {
    const confirm = await confirmReset();
    if (confirm) {
      LocalStorage.clearStorage();
      window.location.reload();
    }
  }

  return (
    <>
      <Header handleClickMenu={toggleStepsDrawer} />
      <Drawer
        open={showSteps}
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        onClose={toggleStepsDrawer}
        sx={styles.stepsDrawer}
      >
        <List sx={styles.stepsList}>
          {registrationSteps.map((item, index) => (
            <RegistrationStep
              key={index}
              step={item}
              index={index}
              completed={cachedStepKeys.includes(item.key)}
              disabled={cachedStepKeys.length <= index}
              handleClickStep={handleClickRegistrationStep}
              isCurrentStep={currentStep.key === item.key}
            />
          ))}
        </List>
        <Stack direction={"row"} justifyContent={"space-evenly"}>
          <Button variant="contained" onClick={handleClickReset} color="error">
            Start over
          </Button>
          <Link to={"/auth/login"}>
            <Button variant="text">Go to Login</Button>
          </Link>
        </Stack>
      </Drawer>
      <div
        style={{
          ...styles.contentBox,
          ...(!showSteps && styles.contentBox.wide),
        }}
      >
        <Container maxWidth={"lg"} style={styles.currentStepWrapper}>
          <FormHelperText error sx={{ textAlign: "right" }}>
            Fields marked with * are required
          </FormHelperText>
          {currentStep.component}
        </Container>
      </div>
      <Dialog
        onClose={() => {}}
        open={!selectedCountry}
        scroll="paper"
        fullWidth
      >
        <SelectCountryForm handleClickContinue={handleSelectCountry} />
      </Dialog>
      <ResetAlert />
    </>
  );
};

export default RegisterPage;
