import { Divider, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

const loaderElemts: any[] = [];

for (let i = 0; i < 3; i++) {
  let element = (
    <Grid item xs={11.8} mb={4} key={i}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Skeleton variant="text" width={"10%"} />
        <Skeleton variant="text" width={"15%"} />
        <Skeleton variant="text" width={"15%"} />
        <Skeleton variant="text" width={"8%"} />
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Skeleton variant="text" width={"8%"} height={12} />
        <Skeleton variant="text" width={"10%"} height={12} />
        <Skeleton variant="text" width={"12%"} height={12} />
        <Skeleton variant="text" width={"6%"} height={12} />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={2}
        ml={2}
      >
        <Skeleton variant="rectangular" width={"30%"} height={50} />
        <Skeleton variant="text" width={"15%"} height={18} />
        <Skeleton variant="text" width={"15%"} height={18} />
        <Skeleton variant="circular" width={40} height={40} />
      </Stack>
      <Divider sx={{mt: 1}}/>
    </Grid>
  );
  loaderElemts.push(element);
}

export const OrdersLoader: React.FC = () => {
  return <>{loaderElemts}</>;
};
