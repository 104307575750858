import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { TrackingDetailsModel } from "../../models/TrackingDetailsModel";
import {
  ArrowRight,
  Inventory,
  NotesOutlined,
  NumbersOutlined,
  TrackChanges,
} from "@mui/icons-material";
import { productStatusesMap } from "../../models/OrderModel";
import { AppColors } from "../../common/AppColors";

interface Props {
  details: TrackingDetailsModel;
}

export const TrackingDetails: React.FC<Props> = (props) => {
  let details = props.details;
  const deliveryStatus = productStatusesMap.get(details.status)!;

  return (
    <Card
      sx={{
        mt: {
          boxShadow: "5px 5px 20px #00000029",
          marginBottom: "10px",
        },
      }}
    >
      <CardContent>
        <List>
          <ListItem sx={{ bgcolor: AppColors.LightColor }}>
            <ListItemIcon>
              <Inventory />
            </ListItemIcon>
            <ListItemText
              primary={details.name}
              secondary={details.attributes}
            />
          </ListItem>
          <ListItem sx={{ bgcolor: AppColors.LightGray }}>
            <ListItemIcon>
              <NumbersOutlined />
            </ListItemIcon>
            <ListItemText primary={details.quantity} secondary={"Quantity"} />
          </ListItem>
          <ListItem sx={{ bgcolor: AppColors.LightColor }}>
            <ListItemIcon>
              <Avatar
                sx={{ color: AppColors.White, bgcolor: deliveryStatus.color }}
              >
                <deliveryStatus.icon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={deliveryStatus?.title}
              secondary={"Delivery Status"}
              primaryTypographyProps={{
                color: deliveryStatus.color,
                fontWeight: "bold",
                fontSize: "medium",
              }}
            />
          </ListItem>
          <ListItem sx={{ bgcolor: AppColors.LightGray }}>
            <ListItemIcon>
              <NotesOutlined />
            </ListItemIcon>
            <ListItemText primary={details.note ?? "N/A"} secondary={"Note"} />
          </ListItem>
          {details.trackingLink && (
            <ListItem sx={{ bgcolor: AppColors.LightGray }}>
              <ListItemIcon>
                <TrackChanges />
              </ListItemIcon>
              <ListItemText            
                primary={
                  <Typography
                    component={"a"}
                    href={details.trackingLink}
                    target="_blank"                    
                    sx={{wordBreak: "break-all"}}
                  >
                    {details.trackingLink}
                  </Typography>
                }
                secondary={"Tracking Link"}
              />
              <ListItemIcon>
                <IconButton
                  onClick={() => {
                    window.open(details.trackingLink, "_blank");
                  }}
                >
                  <ArrowRight />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
};
