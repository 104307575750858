import { StyleModel } from "../models/StyleModel";
import { auth } from "./Auth";
import { cart } from "./Cart";
import { categoryProductPage } from "./CategoryProductPage";
import { checkoutPage } from "./CheckoutPage";
import { errorPage } from "./ErrorPage";
import { footer } from "./Footer";
import { header } from "./Header";
import { homePage } from "./HomePage";
import { orders } from "./OrdersPage";
import { productPage } from "./ProductPage";
import { productTrackingPage } from "./ProductTrackingPage";
import { profilePage } from "./ProfilePage";
import { resetPassword } from "./ResetPasswordPage";
import { wishlistPage } from "./Wishlist";

export const styleDictionary = new Map<string, StyleModel>();

styleDictionary.set("auth", auth);
styleDictionary.set("footer", footer);
styleDictionary.set("header", header);
styleDictionary.set("profile", profilePage);
styleDictionary.set("productPage", productPage);
styleDictionary.set("cart", cart);
styleDictionary.set("checkout", checkoutPage);
styleDictionary.set("orders", orders);
styleDictionary.set("resetPassword", resetPassword);
styleDictionary.set("productTrackingPage", productTrackingPage);
styleDictionary.set("homePage", homePage);
styleDictionary.set("wishlistPage", wishlistPage);
styleDictionary.set("categoryProductsPage", categoryProductPage);
styleDictionary.set("errorPage", errorPage)
