import * as yup from "yup";

export const CreditRequestFormSchema = yup.object().shape({
  // Credit Customer For
  expectedMonthlyPurchase: yup
    .number()
    .required()
    .label("Expected Monthly Purchase"),
  creditLimit: yup.number().required().label("Credit Limit"),
  yearEndDate: yup.string().required().label("Year End Date"),
  isAudited: yup.bool().required().label("Is Audited"),
  isPublicCompany: yup.bool().required(),
  type: yup.string().required().label("Business Type/Category"),
});
