import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/shared/Footer";
import { Container, Grid, useMediaQuery } from "@mui/material";
import { StyleContext } from "../context/StyleContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { CartModel } from "../models/CartModel";
import { DeliveryAndBillingForm } from "../components/Checkout/DeliveryAndBillingForm";
import { Helmet } from "react-helmet-async";
import { appTitle, scrollToTop, snakeToCamel } from "../common/Helper";
import { Wallpaper } from "../components/shared/Wallpaper";
import { PaymentDetails } from "../components/Checkout/PaymentDetails";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../reduxenv/Slices/ProfileSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CheckoutAddressModel } from "../models/CheckoutAddressModel";
import { DeliveryAndBillingFormSchema } from "../schemas/DeliveryAndBillingFormSchema";
import { StateModel } from "../models/StateModel";
import { useGetQuotationMutation } from "../services/CheckoutServices";
import { ResponseModel } from "../models/ResponseModel";
import { QuotationModel } from "../models/QuotationModel";
import { setIsLoading } from "../reduxenv/Slices/AppSlice";

export const CheckoutPage: React.FC = () => {
  const navigate = useNavigate();
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(
    styleContext.getComponentStyle("checkout")
  );
  const isMobile = useMediaQuery("(max-width: 786px)");
  const location = useLocation();
  const products = location.state as CartModel[];
  const profile = useSelector(getProfile);
  const [selectedState, setSelectedState] = useState(profile.state);
  const sameAsBilling = "SAMEASBILLING";
  const {
    handleSubmit,
    control,
    setValue,
    formState: errors,
  } = useForm<CheckoutAddressModel>({
    resolver: yupResolver(DeliveryAndBillingFormSchema),
    defaultValues: {
      billingFirstName: profile.firstName,
      billingLastName: profile.lastName,
      billingPhone: profile.phone,
      billingAddress1: profile.address1,
      billingAddress2: profile.address2,
      stateName: selectedState?.stateName!,
      deliveryAddress1: sameAsBilling,
      deliveryAddress2: sameAsBilling,
      deliveryFirstName: sameAsBilling,
      deliveryLastName: sameAsBilling,
      deliveryPhone: sameAsBilling,
    },
  });
  const [quotation, setQuotation] = useState<QuotationModel>();
  const [isExpressShipping, setIsExpressShipping] = useState(
    quotation?.isExpressShipping ?? false
  );
  const [isSaveBillingAddress, setIsSaveBillingAddress] = useState(false);
  const [isSaveDeliveryAddress, setIsSaveDeliveryAddress] = useState(false);
  const [getQuotation] = useGetQuotationMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("checkout"));
    scrollToTop();
    if (!products || products.length === 0) {
      navigate("/explore");
    }
  }, [isMobile]);

  useEffect(() => {
    fetchQuotation();
  }, [selectedState, isExpressShipping]);

  function handleSelectState(state: StateModel) {
    setSelectedState(state);
    setValue("stateName", state.stateName);
  }

  async function fetchQuotation() {
    dispatch(setIsLoading(true));
    getQuotation({
      products: products,
      stateId: selectedState?.id!,
      isExpressShipping,
    })
      .then((quotationResponse: any) => {
        let response = quotationResponse.data as ResponseModel;
        if (response.status === "success") {
          let tempResponse = snakeToCamel(response.response);
          let tempQuotation = tempResponse as QuotationModel;
          setQuotation(tempQuotation);
          dispatch(setIsLoading(false));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setIsLoading(false));
      });
  }

  return (
    <>
      <Helmet>
        <title>{appTitle} - Checkout</title>
      </Helmet>
      <Header />
      <Grid container spacing={2} justifyContent={"center"}>
        {!isMobile && <Wallpaper />}
        <Grid item xs={12}>
          <Container maxWidth={"xl"}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12} lg={6} xl={5} sx={styles.moveUp}>
                <DeliveryAndBillingForm
                  control={control}
                  profile={profile}
                  selectedStateId={selectedState?.id}
                  handleSelectState={handleSelectState}
                  errors={errors}
                  isDeliveryApplicable={quotation?.isDeliveryApplicable!}
                  isExpressShipping={isExpressShipping}
                  setExpressShipping={() =>
                    setIsExpressShipping(!isExpressShipping)
                  }
                  isSaveBillingAddress={isSaveBillingAddress}
                  setIsSaveBillingAddress={(_e, checked) => {
                    setIsSaveBillingAddress(checked);
                  }}
                  setValue={setValue}
                  isSaveDeliveryAddress={isSaveDeliveryAddress}
                  setIsSaveDeliveryAddress={(_e, checked) => {
                    setIsSaveDeliveryAddress(checked);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={5} xl={4} sx={styles.moveUp}>
                <PaymentDetails
                  products={products!}
                  styles={styles.payment}
                  profile={profile}
                  handleSubmit={handleSubmit}
                  state={selectedState!}
                  quotation={quotation!}
                  isSaveDeliveryAddress={isSaveDeliveryAddress}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};
