import { showToastMessage } from "./Helper";

const allowedExtensions = [
  "png",
  "jpeg",
  "jpg",
  "svg",
  "xlsx",
  "xls",
  "pdf",
  "docx",
  "doc",
];
const allowedImageExtensions = ["png", "jpeg", "jpg", "svg"];

export function validateFile(fileName: string, isImage = false) {
  let fileExtension = fileName.split(".").pop();
  let valid = (isImage ? allowedImageExtensions : allowedExtensions).includes(
    fileExtension!.toLowerCase()
  );
  if (!valid) {
    showToastMessage(
      "error",
      "Unsupported File format. Only '" +
        (isImage ? allowedImageExtensions : allowedExtensions).join(", ") +
        "' are supported."
    );
  }
  return valid;
}
