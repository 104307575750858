import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

const loaderElements: any[] = [];

for (let i = 0; i < 3; i++) {
  let element = (
    <Grid key={i} item xs={12} mt={1}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Skeleton variant="rectangular" width={"20%"} height={120} />
        <div style={{ width: "50%" }}>
          <Skeleton variant="text" width={"100%"} height={20} />
          <Skeleton variant="text" width={"80%"} height={20} />
        </div>
        <Skeleton variant="rectangular" width={"20%"} height={40} />
      </Stack>
    </Grid>
  );
  loaderElements.push(element);
}

export const WishlistLoader: React.FC = () => {
  return <>{loaderElements}</>;
};
