import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { ControlledTextField } from "../shared/ControlledTextField";
import { useForm } from "react-hook-form";
import { usePutProfileMutation } from "../../services/ProfileServices";
import { ProfileModel } from "../../models/ProfileModel";
import { StateModel } from "../../models/StateModel";
import { EditProfileFormModel } from "../../models/EditProfileFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditProfileFormSchema } from "../../schemas/EditProfileFormSchema";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../../models/ResponseModel";
import { setProfile } from "../../reduxenv/Slices/ProfileSlice";
import { localStorageKeys, showToastMessage } from "../../common/Helper";
import { StatesSelect } from "../shared/StatesSelect";

interface Props {
  profile: ProfileModel;
  viewProfile(): void;
}

export const EditProfileForm: React.FC<Props> = (props) => {
  let profile = props.profile;
  const dispatch = useDispatch();
  const [putProfile] = usePutProfileMutation();
  const [selectedState, setSelectedState] = useState(profile.state);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditProfileFormModel>({
    resolver: yupResolver(EditProfileFormSchema),
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      phone: profile.phone,
      address1: profile.address1,
      address2: profile.address2,
      state: "",
    },
  });

  async function submit(editProfileModel: EditProfileFormModel) {
    dispatch(setIsLoading(true));
    putProfile(editProfileModel)
      .then((response: any) => {
        let responseModel = response.data as ResponseModel;
        if (responseModel.status === "success") {
          let updatedProfile = { ...profile };
          updatedProfile.firstName = editProfileModel.firstName;
          updatedProfile.lastName = editProfileModel.lastName;
          updatedProfile.phone = editProfileModel.phone;
          updatedProfile.address1 = editProfileModel.address1;
          updatedProfile.address2 = editProfileModel.address2;
          updatedProfile.state = selectedState;
          localStorage.setItem(
            localStorageKeys.profile,
            JSON.stringify(updatedProfile)
          );
          dispatch(setProfile(updatedProfile));
          props.viewProfile();
        }
        showToastMessage(responseModel.status, responseModel.message);
        dispatch(setIsLoading(false));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  }

  function handleSelectState(state: StateModel) {
    setSelectedState(state);
    setValue("state", state.id.toString());
  }

  return (
    <Grid
      container
      spacing={2}
      mt={1}
      justifyContent={"center"}
      component={"form"}
      onSubmit={handleSubmit(submit)}
    >
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label="First Name*"
          name="firstName"
          error={errors.firstName}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label="Last Name*"
          name="lastName"
          error={errors.lastName}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label="Phone*"
          name="phone"
          error={errors.phone}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label="Address Line 1*"
          name="address1"
          error={errors.address1}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          label="Address Line 2*"
          name="address2"
          error={errors.address2}
        />
      </Grid>
      <Grid item xs={12}>
        <StatesSelect
          selectedStateId={selectedState?.id!}
          getSelectedState={handleSelectState}
          countryId={profile.country.id}
          error={errors.state}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <Button fullWidth type="submit" variant="contained">
          Update
        </Button>
      </Grid>
    </Grid>
  );
};
